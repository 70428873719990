import { api } from '@iblai/ibl-web-react-common';
import { getTenant } from '../helpers';

export function getContentCompletionOverTimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdoverviewapi.overviewOrgsCoursesCompletionsRetrieve(...args),
    getTenant(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getCourseCompletionPerCourseAPI(additionalData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdengagement.engagementOrgsCourseCompletionPerCourseRetrieve(
        ...args
      ),
    getTenant(),
    {
      page: additionalData?.page || 1,
    }
  )((err, data, response) => (err, callback(data), response));
}
