import React, { useEffect, useState } from "react";
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import Simplegraphcard from "../../../components/Simplegraphcard/Simplegraphcard";
import Verticalgraphcard from "../../../components/Verticalgraphcard/Verticalgraphcard";
import Gradegraphcard from "../../../components/Gradegraphcard/Gradegraphcard";
import Descriptivesimplegraphcard from "../../../components/DescriptiveSimpleGraphCard/Descriptivesimplegraphcard";
import useOverview from "../../../hooks/useOverview";
import Descrptivegradegraphcard from "../../../components/DescriptiveGradeGraphCard/Descrptivegradegraphcard";
import "./IndexViewContent.css";
import { randomAverageGraphData, randomDescriptiveGraphData, randomVerticalGraphData } from "../../../hooks/helpers";
import _ from "lodash";

const IndexViewContent = () => {
  const {
    averageGrade,
    averageGradeLoaded,
    mostActiveCourses,
    mostActiveCoursesLoaded,
    registeredUsersTotal,
    registeredUsersTotalLoaded,
    activeUsersTotal,
    activeUsersTotalLoaded,
    courseCompletionRate,
    courseCompletionRateLoaded,
    credentialsIssued,
    credentialsIssuedLoaded,
    predictedRegisteredUsers,
    predictedRegisteredUsersLoaded,
    predictedActiveUsers,
    predictedActiveUsersLoaded,
    predictedCourseCompletionRateLoaded,
    predictedCourseCompletionRate,
    predictedCredentialsIssued,
    predictedCredentialsIssuedLoaded,
    predictedAverageGrade,
    predictedAverageGradeLoaded
  } = useOverview();

  const [tempLoadedState, setTempLoadedState] = useState(false);
  // Use environment variable to control AI feature visibility
  const is_ai_enabled = process.env.REACT_APP_IBL_ENABLE_AI !== 'false';


  useEffect(() => {
    setTimeout(() => {
      setTempLoadedState(true);
    }, 3000);
  }, []);

  return (
      <>
        <div className="div-block-183">
          <Descriptivepagetitle />
        </div>
        <div className="overview-wrapper">
          <div className="p-row over-row">
            <Descriptivesimplegraphcard
                data={registeredUsersTotal}
                dataLoaded={registeredUsersTotalLoaded}
                title={"REGISTERED USERS"}
            />
            {is_ai_enabled && <Descriptivesimplegraphcard
                data={predictedRegisteredUsers}
                dataLoaded={predictedRegisteredUsersLoaded}
                title={"PREDICTIVE"}
                isPredictive={true}
            />}
          </div>
          <div className="p-row over-row">
            <Descriptivesimplegraphcard
                data={activeUsersTotal}
                dataLoaded={activeUsersTotalLoaded}
                title={"ACTIVE USERS"}
            />
            {is_ai_enabled && <Descriptivesimplegraphcard
                data={predictedActiveUsers}
                dataLoaded={predictedActiveUsersLoaded}
                title={"PREDICTIVE"}
                isPredictive={true}
            />}
          </div>
          <div className="p-row over-row">
            <Verticalgraphcard
                data={mostActiveCourses}
                dataLoaded={mostActiveCoursesLoaded}
                title={"MOST ACTIVE COURSES"}
                label={"Time Spent"}
                index={1}
            />
            {/* Conditional rendering based on is_ai_enabled flag */}
          </div>
          <div className="p-row over-row">
            <Descriptivesimplegraphcard
                data={courseCompletionRate}
                dataLoaded={courseCompletionRateLoaded}
                title={"COURSE COMPLETION"}
            />
            {is_ai_enabled && <Descriptivesimplegraphcard
                data={predictedCourseCompletionRate}
                dataLoaded={predictedCourseCompletionRateLoaded}
                title={"PREDICTIVE"}
                isPredictive={true}
            />}
          </div>
          <div className="p-row over-row">
            <Descrptivegradegraphcard
                data={averageGrade}
                dataLoaded={averageGradeLoaded}
                title={"AVERAGE GRADE"}
            />
            {is_ai_enabled && <Descrptivegradegraphcard
                data={predictedAverageGrade}
                dataLoaded={predictedAverageGradeLoaded}
                title={"PREDICTIVE"}
                isPredictive={true}
            />}
          </div>
          <div className="p-row over-row">
            <Descriptivesimplegraphcard
                data={credentialsIssued}
                dataLoaded={credentialsIssuedLoaded}
                title={"CREDENTIALS ISSUED"}
            />
            {is_ai_enabled && <Descriptivesimplegraphcard
                data={predictedCredentialsIssued}
                dataLoaded={predictedCredentialsIssuedLoaded}
                title={"PREDICTIVE"}
                isPredictive={true}
            />}
          </div>
        </div>
      </>
  );
};

export default IndexViewContent;
