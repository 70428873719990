import { useEffect, useState } from "react";
import {
  getRegisteredUsersPerCourseAPI,
  getRegisteredUsersOverTimeAPI,
} from "./utils/registeredUsers"; // Replace with the correct path

const useRegisteredUsers = () => {
  const [registeredUsersPerCourse, setRegisteredUsersPerCourse] = useState([]);
  const [registeredUsersPerCourseLoaded, setRegisteredUsersPerCourseLoaded] =
    useState(false);

  const [registeredUsersOverTime, setRegisteredUsersOverTime] = useState([]);
  const [registeredUsersOverTimeLoaded, setRegisteredUsersOverTimeLoaded] =
    useState(false);

  useEffect(() => {
    getRegisteredUsersPerCourseAPI((data) => {
      setRegisteredUsersPerCourse(data);
      setRegisteredUsersPerCourseLoaded(true);
    });

    getRegisteredUsersOverTimeAPI((data) => {
      setRegisteredUsersOverTime(data);
      setRegisteredUsersOverTimeLoaded(true);
      console.log("registeredUsersOverTime", data, "loaded");
    });
  }, []);

  return  {
    registeredUsersPerCourse,
    registeredUsersPerCourseLoaded,
    registeredUsersOverTime,
    registeredUsersOverTimeLoaded,};
};

export default useRegisteredUsers;
