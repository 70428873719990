import { useEffect, useState } from 'react';
import DescriptiveLineGraphCanvas from '../Descrptivelinegraphccanvas/Descrptivelinegraphcanvas';
import '../Descrptivelinegraphccanvas/Descrptivelinegraphcanvas.css';
import {
  DESCRIPTIVE_TIMEFRAMES_OPTIONS,
  getAllTimeDateRange,
  getDataWithinDateRange,
  getLastMonthDateRange,
  getLastWeekDateRange,
  PREDICTIVE_TIMEFRAMES_OPTIONS,
  secondsToHoursAndMinutes,
} from '../../hooks/helpers';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import dayjs from 'dayjs';

const DescriptiveLineChartGraph = ({
  data,
  dataLoaded,
  title,
  isPredictive = false,
  index = 1,
  convertTime = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [percentageChange, setPercentageChange] = useState('');
  const [isPositive, setIsPositive] = useState(true);
  const [timeFrames, setTimeFrames] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  useEffect(() => {
    if (dataLoaded) {
      setFilteredData(data);
      updateData(
        0,
        getLastWeekDateRange,
        'change_last_seven_days',
        'change_last_seven_days_percent'
      );
    }
  }, [dataLoaded, data]);

  useEffect(() => {
    setTimeFrames(
      !isPredictive
        ? DESCRIPTIVE_TIMEFRAMES_OPTIONS
        : PREDICTIVE_TIMEFRAMES_OPTIONS
    );
  }, []);

  const updateData = (index, rangeFunction, changeKey, percentKey) => {
    const rangeData = getDataWithinDateRange(data, rangeFunction(data));
    setTotalValue(rangeData?.total || 0);
    const change = data?.meta?.[changeKey] || 0;
    const percentChange = data?.meta?.[percentKey] || 0;
    const sign = change >= 0 ? '+' : '-';
    const percentageChangeValue = `${sign}${!convertTime ? Math.abs(change) : secondsToHoursAndMinutes(Math.abs(change))} (${Math.floor(percentChange)}%)`;

    setActiveIndex(index);
    setFilteredData(rangeData);
    setPercentageChange(percentageChangeValue);
    setIsPositive(change >= 0);
  };

  const handleClick = (index) => {
    switch (index) {
      case 0:
        updateData(
          index,
          getLastWeekDateRange,
          'change_last_seven_days',
          'change_last_seven_days_percent'
        );
        break;
      case 1:
        updateData(
          index,
          getLastMonthDateRange,
          'change_last_thirty_days',
          'change_last_thirty_days_percent'
        );
        break;
      case 2:
        updateData(index, getAllTimeDateRange, 'at', 'at_percent');
        break;
      default:
        break;
    }
  };

  return (
    <div
      react-component="LineChartGraph"
      className="table_cont"
      style={{ marginTop: 'unset' }}
    >
      <div className="div-block-202 w_w">
        <div className="text-block-109">
          {title}
          <br />
        </div>
        {dataLoaded ? (
          <div className="div-block-237">
            <div className="text-block-110">
              {!convertTime
                ? totalValue
                : `${secondsToHoursAndMinutes(data.total)} hours`}
            </div>
            <div className="div-block-172 flex">
              <div className="div-block-353">
                {timeFrames.map((timeframe, idx) => (
                  <div
                    key={idx}
                    // TODO : style the buttons here properly
                    className={`div-block-20${isPredictive ? '1' : '7'} ${idx === activeIndex ? 'active first' : ''} ${isPredictive && 'prb'}`}
                    onClick={() => handleClick(idx)}
                  >
                    <div className="text-block-111">{timeframe.label}</div>
                  </div>
                ))}
              </div>
              <div className="div-block-207 first active export_to_csv">
                <div className="text-block-111">Export to CSV</div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {!dataLoaded ? (
          <IBLSpinLoader color={'#FFF'} size={20} containerHeight={'200px'} />
        ) : (
          <>
            <div className="div-block-169">
              <div>
                <div className="div-block-170">
                  <div className="div-block-171">
                    <div>
                      <div className="div-block-209">
                        <div
                          className={`text-block-110 per ${isPositive ? 'down up' : 'down'}`}
                        >
                          {percentageChange}
                        </div>
                        <img
                          src={
                            isPositive
                              ? '/images/arrow-up.png'
                              : '/images/or_1or.png'
                          }
                          loading="lazy"
                          width={18}
                          className="image-6 caret"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart-area mg_t_o">
              <div className="html-embed w-embed w-script">
                <DescriptiveLineGraphCanvas
                  data={filteredData}
                  chartId={`${title}-${index}`}
                  isPredictive={isPredictive}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DescriptiveLineChartGraph;
