import $ from 'jquery';
import 'jquery-ui';

export const removeSpaceFromText = (text) => {
  return String(text).trim().replace(/\s\s+/g, ' ');
};

export const showFlex = (element, isStringElement = true) => {
  if (!!isStringElement) {
    $(element).attr('style', 'display:flex');
  } else {
    $(element).attr('style', 'display:flex');
  }
};

export const handlePasswordEyeClick = ({
  setPasswordType,
  isPasswordHidden,
  setIsPasswordHidden,
}) => {
  $(document).on('click', 'form.auth-form .password-eye-block', function () {
    setIsPasswordHidden(!isPasswordHidden);
    //$(this).find('img').toggleClass('hidden')
    const passwordInputEl = $(this).closest('.auth-input-group').find('input');
    const currentPasswordInputType = passwordInputEl.attr('type');
    if (currentPasswordInputType === 'text') {
      setPasswordType('password');
      //passwordInputEl.attr('type', "password")
    } else {
      setPasswordType('text');
      //passwordInputEl.attr('type', "text")
    }
  });
};

export const handleInvalidFieldFocus = () => {
  //MAKE DISAPPEAR INVALID FIELD & TEXT ERROR ON FOCUS
  $('form.auth-form input').on('focus input', function () {
    $(this).removeClass('invalid');
    $(this).closest('.auth-input-group').find('.text-error').addClass('hidden');
  });
};

export const handleNotificationCloseClick = () => {
  $('.notification-close').on('click', function () {
    //$(this).closest('.notification-element').hide("slide", {direction: "right"}, 200);
    $(this).closest('.notification-element').hide();
  });
};

export const resetLoginForm = () => {
  const form = $('form.auth-form');
  form.find('.auth-input-group[data-auth-level="2"]').removeClass('visible');
  form.find('.auth-input-group[data-auth-level="1"]').fadeIn('', () => {
    $(this).addClass('visible');
    form.find('.auth-input-group[data-auth-level="2"]').removeClass('visible');
    form.attr('data-auth-actual-level', '1');
  });
  form.find('.auth-submit-btn').removeClass('disabled');
  form.find('.auth-submit-btn-label').text('Continue');
  form.find('.btn-spinner-block').addClass('hidden');
};

export const resetForgotPasswordForm = () => {
  const form = $('form.auth-form');
  form.find('.auth-submit-btn').removeClass('disabled');
  form.find('.auth-submit-btn-label').text('Continue');
  form.find('.btn-spinner-block').addClass('hidden');
};

export const handleFormSubmitTrigger = () => {
  $('form.auth-form').on('submit', function (e) {
    e.preventDefault();
    $(this).find('.auth-submit-btn:not(.disabled)').trigger('click');
    return false;
  });
};
