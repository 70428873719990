import React, { useContext, useEffect, useRef, useState } from 'react';

import './StudioAuthoringSettingsViewContent.css';
import { getTenant } from '../../../hooks/helpers';
import { setStudioLogo } from '../../../hooks/utils/studio';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const StudioAuthoringSettingsViewContent = () => {
  const inputImgUploadRef = useRef(null);

  const handleUploaderTrigger = () => {
    inputImgUploadRef.current.click();
  };

  const [logoURL, setLogoURL] = useState('');
  const [logoURLUpdating, setLogoURLUpdating] = useState(false);

  const handleImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioLogo(formData, (response) => {
      setLogoURL(response.url);
      setLogoURLUpdating(false);
    });
  };

  const handleLoadSettingsData = () => {
    setLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`
    );
  };

  useEffect(() => {
    handleLoadSettingsData();
  }, []);

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">STUDIO | SETTINGS</div>
        </div>
      </div>
      <div className="div-block-326 l map">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Platform Logo</div>
            <div className="table">
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Logo</div>
                </div>
                <div className="tc tcell _35">
                  {logoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={logoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleImgUploadChange}
                      ref={inputImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleLogoOnChange"
                      className="file-upload light-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudioAuthoringSettingsViewContent;
