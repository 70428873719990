import { api } from '@iblai/ibl-web-react-common';
import { getTenant, determineInputType } from '../helpers';
import { orgSearch } from '@iblai/ibl-web-react-common';

export function getInvitations(inputVal, size = 10, callback, page = 1) {
  api.ibldmplatform.fetchPlatformInvitations(
    { tenant: getTenant, page: page, page_size: size },
    function (data) {
      callback(data.body);
    }
  );
}

export function postInvite(data, callback) {
  if (data.invitationData && data.invitationData.length > 0) {
    let bulkData = {
      invitation_data: data.invitationData,
    };

    if (data.csvType == 'platform') {
      bulkData.platform_key = getTenant();
      api.ibldmplatform.createPlatformBulkInvitations(
        bulkData,
        function (data) {
          callback(data);
        }
      );
    } else if (data.csvType == 'course') {
      bulkData.course_id = '';
      api.ibldmplatform.createCourseBulkInvitations(bulkData, function (data) {
        callback(data);
      });
    } else if (data.csvType == 'program') {
      bulkData.program_key = '';
      api.ibldmplatform.createProgramBulkInvitations(bulkData, function (data) {
        callback(data);
      });
    }
    return;
  }

  const userIdentifier = determineInputType(data.userNameOrEmail);
  const inputs = {
    platform_key: getTenant(),
    ...userIdentifier,
  };

  if (data.numberOfInvites) {
    inputs.source = Date.now();
    inputs.count = parseInt(data.numberOfInvites);
    data.numberOfInvites = parseInt(data.numberOfInvites);
    api.ibldmplatform.createPlatformBulkInvitations(inputs, function (data) {
      callback(data);
    });
    return;
  }

  if (data.type == 'ibl-dm.public.dl_catalog_app_course') {
    inputs.course_id = data.id;
    api.ibldmplatform.createCatalogInvitationsCourse(inputs, function (data) {
      callback(data);
    });
  } else if (data.type == 'ibl-dm.public.dl_catalog_app_program') {
    inputs.program_key = 'program-v1:' + 'main' + '+' + data.id;
    api.ibldmplatform.createCatalogInvitationsProgram(inputs, function (data) {
      callback(data);
    });
  } else if (!data.type) {
    api.ibldmplatform.createCatalogInvitationsPlatform(inputs, function (data) {
      callback(data);
    });
  }
}

export function getCourseProgram(search = '', callback) {
  const data =
    '?multiple_indices_context=' +
    '[{"_index": "ibl-dm.public.dl_catalog_app_course"},{"_index": "ibl-dm.public.dl_catalog_app_program"}]' +
    '&context=' +
    search;
  orgSearch(data, function (data) {
    callback(data);
  });
}

export function postRedeem(data, callback) {
  const userIdentifier = determineInputType(data.userNameOrEmail);
  const inputs = {
    platform_key: getTenant(),
    ...userIdentifier,
    source: data.source,
  };

  api.ibldmplatform.redeemPlatformInvitations(inputs, function (data) {
    callback();
  });
}
