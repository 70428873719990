import React, { useEffect, useState } from 'react';
import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';
import iblLogo from '../../assets/images/ibl-logo-animated.gif';
// import closeLogoImg from '../../assets/images/close-1_1close-1.png'
// import xImg from '../../assets/images/x.svg'
// import infoImg from '../../assets/images/information_1information.png'
// import checkImg from '../../assets/images/check-1_1check-1.png'
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick,
  removeSpaceFromText,
  resetLoginForm,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';

const Signup = () => {

    const [passwordType, setPasswordType] = useState('password')
    const [confirmPasswordType, setConfirmPasswordType] = useState('password')
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true)
    const [notifications, notify, onClose] = useNotification();
    const [mfeContext, setMfeContext] = useState(null);

    const handleResetForm = () => {
        resetLoginForm()
    }
    const handleSignupSubmit = ({email, username, password, handleResetForm, notify}) => {
        window.iblwebauth.registerUser({
            email,
            username,
            password
        }, (data) => {
            window.location.href = data["redirect_uri"];
        }, (error) => {
            handleResetForm();
            notify("An Error Occurred", Object.values(error.responseJSON.message).join("\n"), "error");
        });
    }
    const formSubmit = () => {
        $('body form.auth-form .auth-submit-btn:not(.disabled)').on('click', function (e) {
            e.preventDefault()
            let formIsInvalid = false
            const formParent = $(this).closest('form')
            const actualFormLevel = parseInt(formParent.attr('data-auth-actual-level'))
            const emailField = $('form.auth-form input.email')
            const typedEmail = emailField.val()
            const emailTextError = emailField.closest('.auth-input-group').find('.text-error')

            const usernameField = $('form.auth-form input.username')
            const typedUsername = usernameField.val()
            const usernameTextError = usernameField.closest('.auth-input-group').find('.text-error')
            const passwordField = $('form.auth-form input.password')
            const typedPassword = passwordField.val()
            const passwordTextError = passwordField.closest('.auth-input-group').find('.text-error')

            const confirmPasswordField = $('form.auth-form input.password-confirm')
            const typedConfirmPassword = confirmPasswordField.val()
            const confirmPasswordTextError = confirmPasswordField.closest('.auth-input-group').find('.text-error')
            if (actualFormLevel === 1) {

                if (!!!removeSpaceFromText(typedEmail)) {
                    //CHECKING IF EMPTY EMAIL FIELD
                    formIsInvalid = true
                    emailField.addClass('invalid')
                    emailTextError.text('This field is required')
                    emailTextError.removeClass('hidden')
                } else if (!String(typedEmail).includes('@')) {
                    //CHECKING IF EMAIL FIELD DOES CONTAIN @
                    formIsInvalid = true
                    emailField.addClass('invalid')
                    emailTextError.text('Must be a valid email')
                    emailTextError.removeClass('hidden')
                }
                if (!!!removeSpaceFromText(typedUsername)) {
                    //CHECKING IF EMPTY EMAIL FIELD
                    formIsInvalid = true
                    usernameField.addClass('invalid')
                    usernameTextError.text('This field is required')
                    usernameTextError.removeClass('hidden')
                } else if (String(typedUsername).length < 4 || String(typedUsername).length > 32) {
                    //CHECKING IF USERNAME LENGTH IS VALID
                    formIsInvalid = true
                    usernameField.addClass('invalid')
                    usernameTextError.text('Must be more than 4 and less than 32 characters')
                    usernameTextError.removeClass('hidden')
                } else if (!!!String(typedUsername).match(/^[a-zA-Z0-9]+$/)) {
                    //CHECKING IF USERNAME IS ALPHANUMERIC
                    formIsInvalid = true
                    usernameField.addClass('invalid')
                    usernameTextError.text('Must be alphanumeric only')
                    usernameTextError.removeClass('hidden')
                }
                if (!!formIsInvalid) {
                    //DON'T CONTINUE IF FORM INVALID
                    return false
                }
                //HIDE PREVIOUS FORM LEVEL & SHOW NEXT FORM LEVEL LAYOUT
                formParent.find('input').removeClass('invalid')
                formParent.find('.text-error').addClass('hidden')
                $(`form.auth-form .auth-input-group[data-auth-level=1]`).fadeOut('', () => {
                    $(this).removeClass('visible')
                    formParent.attr('data-auth-actual-level', '2')
                    $(`form.auth-form .auth-input-group[data-auth-level=2]`).addClass('visible')
                })

            } else {


                if (!!!removeSpaceFromText(typedPassword)) {
                    //CHECKING IF EMPTY PASSWORD FIELD
                    formIsInvalid = true
                    passwordField.addClass('invalid')
                    passwordTextError.text('This field is required')
                    passwordTextError.removeClass('hidden')
                } else if (String(typedPassword).length < 8) {
                    formIsInvalid = true
                    passwordField.addClass('invalid')
                    passwordTextError.text('Must be more than 8 characters')
                    passwordTextError.removeClass('hidden')
                }
                if (!!!removeSpaceFromText(typedConfirmPassword)) {
                    //CHECKING IF EMPTY PASSWORD FIELD
                    formIsInvalid = true
                    confirmPasswordField.addClass('invalid')
                    confirmPasswordTextError.text('This field is required')
                    confirmPasswordTextError.removeClass('hidden')
                } else if (typedPassword !== typedConfirmPassword) {
                    formIsInvalid = true
                    confirmPasswordField.addClass('invalid')
                    confirmPasswordTextError.text('Must match the password field')
                    confirmPasswordTextError.removeClass('hidden')
                }
                if (!!formIsInvalid) {
                    //DON'T CONTINUE IF FORM INVALID
                    return false
                }
                //SUBMISSION START
                formParent.find('input').removeClass('invalid')
                formParent.find('.text-error').addClass('hidden')
                const submitButtonLabelElement = $(this).find('.auth-submit-btn-label')
                submitButtonLabelElement.text('Submitting')
                $(this).addClass('disabled')
                $(this).find('.btn-spinner-block').removeClass('hidden')
                handleSignupSubmit(
                    {
                        email: String(typedEmail).trim(),
                        username: String(typedUsername).trim(),
                        password: String(typedPassword).trim(),
                        handleResetForm,
                        notify
                    })
                //$('.notification-element.error').show("slide", {direction: "right"}, 200);
                // $('.notification-element.error').show();
                // setTimeout(() => {
                //     //$('.notification-element.info').show("slide", {direction: "right"}, 200);
                //     $('.notification-element.info').show();
                // }, 2000)
                // setTimeout(() => {
                //     //$('.notification-element.success').show("slide", {direction: "right"}, 200);
                //     $('.notification-element.success').show();
                //     //window.location.href = "https://ibl-aimentorpro.webflow.io/"
                // }, 4000)
                return false;

            }
        })
    }

    useEffect(() => {
        handleFormSubmitTrigger()
        formSubmit()
        handleInvalidFieldFocus()
        handleNotificationCloseClick()
        window.ibledxmfe.getMfeContext(data => {
            setMfeContext(data);
            console.log(data)
        })
    }, []);

    useEffect(() => {
        handlePasswordEyeClick({setPasswordType, isPasswordHidden, setIsPasswordHidden}, "#")
    }, [isPasswordHidden]);
    useEffect(() => {
        handlePasswordEyeClick({setPasswordType : setConfirmPasswordType, isPasswordHidden :isConfirmPasswordHidden, setIsPasswordHidden :setIsConfirmPasswordHidden})
    }, [isConfirmPasswordHidden]);

    return (
        <>
            <div className="w-layout-vflex auth-page-container">
                <div className="w-layout-vflex auth-page-block"><img src={iblLogo} loading="lazy"
                                                                     alt="" className="auth-logo-img"/>
                    <div className="w-layout-vflex auth-page-main-content">
                        <h2 className="auth-page-title">Create your account</h2>
                        <div className="auth-title-desc">Email verification may be required.</div>
                        <div className="auth-form-block w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get"
                                  data-auth-actual-level="1" className="auth-form"
                                  data-wf-page-id="659e8e273e49ec9da46f5849"
                                  data-wf-element-id="afe8801e-2b2f-93ee-84f8-31aafd35eb1a">
                                <div id="email" data-auth-level="1" className="w-layout-vflex auth-input-group visible">
                                    <input className="auth-input email w-input" autoFocus={true} maxLength="256"
                                           name="email" data-name="Email" placeholder="Email address" type="email"
                                           id="email"/>
                                    <div className="text-error hidden">Must be a valid email adress</div>
                                </div>
                                <div data-auth-level="1" className="w-layout-vflex auth-input-group visible"><input
                                    className="auth-input username w-input" maxLength="256" name="username"
                                    data-name="Username" placeholder="Username" type="text" id="username"/>
                                    <div className="text-error hidden">Only alphanumeric &amp; less than 32 characters
                                    </div>
                                </div>
                                <div data-auth-level="2" className="w-layout-vflex auth-input-group"><input
                                    className="auth-input password-field password w-input" maxLength="256"
                                    name="password" data-name="Password" placeholder="Password" type={passwordType}
                                    id="password"/>
                                    <div className="text-error hidden">Minimum 8 characters</div>
                                    <div className="w-layout-hflex password-eye-block" id="password_eye"><img
                                        loading="lazy" alt=""
                                        className="password-eye-icon hidden"/><img
                                        src={!isPasswordHidden ? eyeOff : eye} loading="lazy" alt=""
                                        className="password-eye-icon"/>
                                    </div>
                                </div>
                                <div data-auth-level="2" className="w-layout-vflex auth-input-group"><input
                                    className="auth-input password-field password-confirm w-input" maxLength="256"
                                    name="password-confirm" data-name="Password Confirm" placeholder="Confirm password"
                                    type={confirmPasswordType} id="password-confirm"/>
                                    <div className="text-error hidden">Minimum 8 characters</div>
                                    <div className="w-layout-hflex password-eye-block" id="confirm_password_eye"><img
                                        loading="lazy" alt=""
                                        className="password-eye-icon hidden"/><img
                                        src={!isConfirmPasswordHidden ? eyeOff : eye} loading="lazy" alt=""
                                        className="password-eye-icon"/>
                                    </div>
                                </div>
                                <div className="w-layout-hflex auth-submit-btn">
                                    <div className="auth-submit-btn-label">Continue</div>
                                    <div className="btn-spinner-block hidden w-embed">
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" data-wait="Please wait..." className="submit-button-2 w-button"
                                       value="Submit"/>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                        <div className="w-layout-hflex auth-redirect-block">
                            <div className="text-block-31">Already have an account ?</div>
                            <Link to="/login" className="auth-link-redirect">Log In</Link>
                            {/*<a href="index.html" className="auth-link-redirect">Log In</a>*/}
                        </div>
                        {   
                            (mfeContext && mfeContext.context_data.providers.length > 0) &&
                            <div className="w-layout-hflex auth-seperation-block">
                                <div className="div-block-36"></div>
                                <div className="text-block-30">OR</div>
                                <div className="div-block-36"></div>
                            </div>
                        }
                        {mfeContext && mfeContext.context_data.providers.map(provider => (
                            <a href={`${process.env.IBL_WEB_URL}/join?provider=${provider.id}`} className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={provider.iconClass} loading="lazy" alt=""
                                    className="image-31"/>
                                    <div className="external-auth-label">Continue with {provider.name}</div>
                                </div>
                            </a>
                        ))}
                        
                        {/* <div className="w-layout-vflex external-auth-block">
                            <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={googleLogo} loading="lazy" alt=""
                                    className="image-31"/>
                                    <div className="external-auth-label">Continue with Google</div>
                                </div>
                            </a>
                            <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={appleLogo} loading="lazy" alt="" className="image-31"/>
                                    <div className="external-auth-label">Continue with Apple</div>
                                </div>
                            </a>
                            <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={microsoftLogo} loading="lazy" alt="" className="image-31"/>
                                    <div className="external-auth-label">Continue with Microsoft Account</div>
                                </div>
                            </a>
                        </div> */}
                    </div>
                    <div className="w-layout-hflex auth-footer">
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Terms of Use</div>
                        </a>
                        <div className="div-block-35"></div>
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Privacy Policy</div>
                        </a>
                    </div>
                </div>
            </div>
            {Object.entries(notifications).map(([key, notificationObj]) => (
                <Notifications onClose={() => onClose(key)} key={key} displayMsg={notificationObj.displayMsg} headerMsg={notificationObj.headerMsg} type={notificationObj.type} />
            ))}
            {/* <Notifications/> */}
        </>
    )
}

export default Signup