import React, { useEffect } from 'react';
import iblLogo from '../../assets/images/ibl-logo-animated.gif';
// import closeLogoImg from '../../assets/images/close-1_1close-1.png'
// import xImg from '../../assets/images/x.svg'
// import infoImg from '../../assets/images/information_1information.png'
// import checkImg from '../../assets/images/check-1_1check-1.png'
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  removeSpaceFromText,
  resetForgotPasswordForm,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';

const ForgotPassword = () => {
    const [notifications, notify, onClose] = useNotification();
    const handleResetForm = () => {
        resetForgotPasswordForm()
    }

    const handleForgotPasswordSubmit = ({email, handleResetForm, notify}) => {
        window.iblwebauth.forgetPassword({
            email
        }, (data) => {
            handleResetForm();
            notify("Reset Password Initiated", data.message, "success");
            setTimeout(() => {
            }, 4000);
        }, (error) => {
            handleResetForm();
            notify("An Error Occurred", Object.values(error.responseJSON.message).join("\n"), "error");
        });
    }
    const formSubmit = () => {
        $('body form.auth-form .auth-submit-btn:not(.disabled)').on('click', function (e) {
            e.preventDefault()
            let formIsInvalid = false
            const formParent = $(this).closest('form')

            const emailField = $('form.auth-form input.email')
            const typedEmail = emailField.val()
            const emailTextError = emailField.closest('.auth-input-group').find('.text-error')
            if (!!!removeSpaceFromText(typedEmail)) {
                //CHECKING IF EMPTY EMAIL FIELD
                formIsInvalid = true
                emailField.addClass('invalid')
                emailTextError.text('This field is required')
                emailTextError.removeClass('hidden')
            } else if (!String(typedEmail).includes('@')) {
                //CHECKING IF EMAIL FIELD DOES CONTAIN @
                formIsInvalid = true
                emailField.addClass('invalid')
                emailTextError.text('Must be a valid email')
                emailTextError.removeClass('hidden')
            }
            if (!!formIsInvalid) {
                //DON'T CONTINUE IF FORM INVALID
                return false
            }
            //SUBMISSION START
            formParent.find('input').removeClass('invalid')
            formParent.find('.text-error').addClass('hidden')
            const submitButtonLabelElement = $(this).find('.auth-submit-btn-label')
            submitButtonLabelElement.text('Submitting')
            $(this).addClass('disabled')
            $(this).find('.btn-spinner-block').removeClass('hidden')
            handleForgotPasswordSubmit(
                {
                    email: String(typedEmail).trim(),
                    handleResetForm,
                    notify
                })
            //$('.notification-element.error').show("slide", {direction: "right"}, 200);
            $('.notification-element.error').show();
            setTimeout(() => {
                //$('.notification-element.info').show("slide", {direction: "right"}, 200);
                $('.notification-element.info').show();
            }, 2000)
            setTimeout(() => {
                //$('.notification-element.success').show("slide", {direction: "right"}, 200);
                $('.notification-element.success').show();
                //window.location.href = "https://ibl-aimentorpro.webflow.io/"
            }, 4000)
            return false;
        })
    }

    useEffect(() => {
        handleFormSubmitTrigger()
        formSubmit()
        handleInvalidFieldFocus()
        handleNotificationCloseClick()
    }, []);

    return (
        <>
            <div className="w-layout-vflex auth-page-container">
                <div className="w-layout-vflex auth-page-block"><img src={iblLogo} loading="lazy"
                                                                     alt="" className="auth-logo-img"/>
                    <div className="w-layout-vflex auth-page-main-content">
                        <h2 className="auth-page-title smaller">Forgot your password</h2>
                        <div className="auth-title-desc">Enter your email address and we will send you instructions to
                            reset your password.
                        </div>
                        <div className="auth-form-block w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get"
                                  className="auth-form" data-wf-page-id="659e918e1daef6da517bc3a0"
                                  data-wf-element-id="e3e88604-298f-9479-5782-2f71afa6dae7">
                                <div data-auth-level="1" className="w-layout-vflex auth-input-group visible"><input
                                    className="auth-input email w-input" autoFocus="true" maxLength="256" name="email"
                                    data-name="Email" placeholder="Email" type="email" id="email" required=""/>
                                    <div className="text-error hidden">This field is required</div>
                                </div>
                                <div className="w-layout-hflex auth-submit-btn">
                                    <div className="auth-submit-btn-label">Continue</div>
                                    <div className="btn-spinner-block hidden w-embed">
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" data-wait="Please wait..." className="submit-button-2 w-button"
                                       value="Submit"/>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                        <div className="w-layout-hflex auth-redirect-block">
                            <div className="text-block-31">Already have an account ?</div>
                            <Link to="/login" className="auth-link-redirect">Log In</Link>
                            {/*<a href="index.html" className="auth-link-redirect">Log In</a>*/}
                        </div>
                    </div>
                    <div className="w-layout-hflex auth-footer">
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Terms of Use</div>
                        </a>
                        <div className="div-block-35"></div>
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Privacy Policy</div>
                        </a>
                    </div>
                </div>
            </div>
            {Object.entries(notifications).map(([key, notificationObj]) => (
                <Notifications onClose={() => onClose(key)} key={key} displayMsg={notificationObj.displayMsg} headerMsg={notificationObj.headerMsg} type={notificationObj.type} />
            ))}
            {/* <Notifications/> */}
        </>
    )
}

export default ForgotPassword