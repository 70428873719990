import React, { useEffect, useState } from 'react';
import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';
import iblLogo from '../../assets/images/ibl-logo-animated.gif';
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick,
  removeSpaceFromText,
} from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';

const ResetPassword = () => {
    const [passwordType, setPasswordType] = useState('password')
    const [confirmPasswordType, setConfirmPasswordType] = useState('password')
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true)
    const [notifications, notify, onClose] = useNotification();
    let { token } = useParams();

    const handleResetPasswordSubmit = ({password, token, notify}) => {
        window.iblwebauth.resetPassword({password, reset_password_token: token}, (data) => {
            notify("Reset Password Successful", data.message, "success");
        }, (error) => {
            notify("An Error Occurred", error.responseJSON.message, "error");
        })
    }
    const formSubmit = () => {
        $('body form.auth-form .auth-submit-btn:not(.disabled)').on('click', function (e) {
            e.preventDefault()
            let formIsInvalid = false
            const formParent = $(this).closest('form')

            const passwordField = $('form.auth-form input.password')
            const typedPassword = passwordField.val()
            const passwordTextError = passwordField.closest('.auth-input-group').find('.text-error')

            const confirmPasswordField = $('form.auth-form input.password-confirm')
            const typedConfirmPassword = confirmPasswordField.val()
            const confirmPasswordTextError = confirmPasswordField.closest('.auth-input-group').find('.text-error')

            if (!!!removeSpaceFromText(typedPassword)) {
                //CHECKING IF EMPTY PASSWORD FIELD
                formIsInvalid = true
                passwordField.addClass('invalid')
                passwordTextError.text('This field is required')
                passwordTextError.removeClass('hidden')
            } else if (String(typedPassword).length < 8) {
                formIsInvalid = true
                passwordField.addClass('invalid')
                passwordTextError.text('Must be more than 8 characters')
                passwordTextError.removeClass('hidden')
            }
            if (!!!removeSpaceFromText(typedConfirmPassword)) {
                //CHECKING IF EMPTY PASSWORD FIELD
                formIsInvalid = true
                confirmPasswordField.addClass('invalid')
                confirmPasswordTextError.text('This field is required')
                confirmPasswordTextError.removeClass('hidden')
            } else if (typedPassword !== typedConfirmPassword) {
                formIsInvalid = true
                confirmPasswordField.addClass('invalid')
                confirmPasswordTextError.text('Must match the password field')
                confirmPasswordTextError.removeClass('hidden')
            }
            if (!!formIsInvalid) {
                //DON'T CONTINUE IF FORM INVALID
                return false
            }
            //SUBMISSION START
            formParent.find('input').removeClass('invalid')
            formParent.find('.text-error').addClass('hidden')
            const submitButtonLabelElement = $(this).find('.auth-submit-btn-label')
            submitButtonLabelElement.text('Submitting')
            $(this).addClass('disabled')
            $(this).find('.btn-spinner-block').removeClass('hidden')
            handleResetPasswordSubmit(
                {
                    password: String(typedPassword).trim(),
                    token,
                    notify
                })
            //$('.notification-element.error').show("slide", {direction: "right"}, 200);
            // $('.notification-element.error').show();
            // setTimeout(() => {
            //     //$('.notification-element.info').show("slide", {direction: "right"}, 200);
            //     $('.notification-element.info').show();
            // }, 2000)
            // setTimeout(() => {
            //     //$('.notification-element.success').show("slide", {direction: "right"}, 200);
            //     $('.notification-element.success').show();
            //     //window.location.href = "https://ibl-aimentorpro.webflow.io/"
            // }, 4000)
            return false;
        })
    }

    useEffect(() => {
        handleFormSubmitTrigger()
        formSubmit()
        handleInvalidFieldFocus()
        handleNotificationCloseClick()
    }, []);

    useEffect(() => {
        handlePasswordEyeClick({setPasswordType, isPasswordHidden, setIsPasswordHidden}, "#")
    }, [isPasswordHidden]);
    useEffect(() => {
        handlePasswordEyeClick({setPasswordType : setConfirmPasswordType, isPasswordHidden :isConfirmPasswordHidden, setIsPasswordHidden :setIsConfirmPasswordHidden})
    }, [isConfirmPasswordHidden]);

    return (
        <>
            <div className="w-layout-vflex auth-page-container">
                <div className="w-layout-vflex auth-page-block"><img src={iblLogo} loading="lazy"
                                                                     alt="" className="auth-logo-img"/>
                    <div className="w-layout-vflex auth-page-main-content">
                        <h2 className="auth-page-title smaller">Forgot your password</h2>
                        <div className="auth-title-desc">Enter your email address and we will send you instructions to
                            reset your password.
                        </div>
                        <div className="auth-form-block w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get"
                                  className="auth-form" data-wf-page-id="659e918e1daef6da517bc3a0"
                                  data-wf-element-id="e3e88604-298f-9479-5782-2f71afa6dae7">
                                <div className="w-layout-vflex auth-input-group visible"><input
                                    className="auth-input password-field password w-input" maxLength="256"
                                    name="password" data-name="Password" placeholder="Password" type={passwordType}
                                    id="password"/>
                                    <div className="text-error hidden">Minimum 8 characters</div>
                                    <div className="w-layout-hflex password-eye-block" id="password_eye"><img
                                        loading="lazy" alt=""
                                        className="password-eye-icon hidden"/><img
                                        src={!isPasswordHidden ? eyeOff : eye} loading="lazy" alt=""
                                        className="password-eye-icon"/>
                                    </div>
                                </div>
                                <div className="w-layout-vflex auth-input-group visible"><input
                                    className="auth-input password-field password-confirm w-input" maxLength="256"
                                    name="password-confirm" data-name="Password Confirm" placeholder="Confirm password"
                                    type={confirmPasswordType} id="password-confirm"/>
                                    <div className="text-error hidden">Minimum 8 characters</div>
                                    <div className="w-layout-hflex password-eye-block" id="confirm_password_eye"><img
                                        loading="lazy" alt=""
                                        className="password-eye-icon hidden"/><img
                                        src={!isConfirmPasswordHidden ? eyeOff : eye} loading="lazy" alt=""
                                        className="password-eye-icon"/>
                                    </div>
                                </div>
                                <div className="w-layout-hflex auth-submit-btn">
                                    <div className="auth-submit-btn-label">Continue</div>
                                    <div className="btn-spinner-block hidden w-embed">
                                        <div className="lds-ring">
                                        <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" data-wait="Please wait..." className="submit-button-2 w-button"
                                       value="Submit"/>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                        <div className="w-layout-hflex auth-redirect-block">
                            <div className="text-block-31">Already have an account ?</div>
                            <Link to="/login" className="auth-link-redirect">Log In</Link>
                            {/*<a href="index.html" className="auth-link-redirect">Log In</a>*/}
                        </div>
                    </div>
                    <div className="w-layout-hflex auth-footer">
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Terms of Use</div>
                        </a>
                        <div className="div-block-35"></div>
                        <a href="#" className="link-block w-inline-block">
                            <div className="auth-footer-links">Privacy Policy</div>
                        </a>
                    </div>
                </div>
            </div>
            {Object.entries(notifications).map(([key, notificationObj]) => (
                <Notifications onClose={() => onClose(key)} key={key} displayMsg={notificationObj.displayMsg} headerMsg={notificationObj.headerMsg} type={notificationObj.type} />
            ))}
            {/* <Notifications/> */}
        </>
    )
}

export default ResetPassword