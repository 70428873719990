import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Descrptivegradegraphcard = ({
  data,
  dataLoaded,
  title,
  isPredictive = false,
}) => {
  return (
    <div className="div-block-204 l average-graph-container">
      <div className="table_cont ns">
        <div className="gr_div mgt0 ov-d">
          <div className="div-block-211 mob">
            <div className="div-block-169 al-c">
              <div>
                <div className="tp avgc">
                  <div
                    prop-data-value-cardtitle="AVERAGE GRADE"
                    prop-data-names="cardTitle"
                    className="text-block-109 o-t"
                  >
                    {title || 'AVERAGE GRADE'}
                    <br />
                  </div>
                  <div className="tp_trigger hidden">
                    <img
                      src="images/info-1_1info-1.png"
                      loading="lazy"
                      alt=""
                      className="image-35"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tp_content sho">
              <div className="text-block-126 hidden">
                This represents the average course grade against the average
                grade needed to pass the courses.
              </div>
            </div>
          </div>
          {!dataLoaded ? (
            <IBLSpinLoader size={20} color={'#FFF'} containerHeight={'200px'} />
          ) : (
            <div className="div-block-212 ov-12">
              <div className="html-embed-5 w-embed">
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      '\n/*** <--- CIRCLE STYLES ---> ***/\n.af-view .wrap-circles {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n}\n.af-view .circle {\n  position: relative;\n  width: 220px;\n  height: 220px;\n  border-radius: 50%;\n  overflow: hidden;\n}\n.af-view .circle .inner {\n  display: flex;\n  flex-direction:column;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 150px;\n  height: 150px;\n  background: #21252b;\n  border-radius: 50%;\n  font-size: 32px;\n  font-weight: 300;\n  color: #fff;\n}\n.af-view .inner span{\n  font-size:40px;\n  line-height:40px;\n}\n.af-view .inner div {\n  font-size:20px;\n}\n  ',
                  }}
                />
                <div
                  className="circle per-25 p86"
                  style={{
                    backgroundImage: `conic-gradient(from 155deg, ${!isPredictive ? '#527bcd' : '#34652d'} ${data.data.average}%, #525252 0)`,
                  }}
                >
                  <div className="inner">
                    <span>{data.data.average}%</span>
                    <div>Average</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Descrptivegradegraphcard;
