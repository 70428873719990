import { createContext, useEffect, useState } from 'react';
import { AuthIframe, api, inIframe } from '@iblai/ibl-web-react-common';
import './App.css';

import '@iblai/ibl-web-react-common/dist/index.css';
import route from './routes';
import { AppDataContext, isJSON } from '@iblai/ibl-web-react-common';
import { RouterProvider } from 'react-router-dom';

//window.api = api

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData());
  const [tokenFromAuthSPA, setTokenFromAuthSPA] = useState(null);
  useEffect(() => {
    api.initiateAxdClientsWithToken(appData.axd_token);
  }, [tokenFromAuthSPA]);
  const authHandler = (authToken, dmToken) => {
    api.iblwebauth.initializeLocalStorageWithAuthData(authToken, dmToken);
    setAppData(initiateAppContextData());
    api.initiateAxdClientsWithToken(localStorage.getItem('axd_token'));
    setTokenFromAuthSPA(authToken);
  };
  return (
    <>
      <AppDataContext.Provider value={{ appData, setAppData }}>
        <RouterProvider router={route(appData)} />
      </AppDataContext.Provider>
      <AuthIframe token={appData.token} authHandler={authHandler} />
    </>
  );
}

export default App;
