import { api } from '@iblai/ibl-web-react-common';
import {
  beginningDateRange,
  getTenant,
  getUserId,
  getUserName,
} from '../helpers';

export function getUserEarnedSkillsAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdskillsapi.skillsOrgsSkillsUsersRetrieve(...args),
    getTenant(),
    getUserId()
  )((err, data, response) => (err, callback(data), response));
}

export function getAverageGradeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdoverviewapi.overviewOrgsAverageGradeRetrieve(...args),
    getTenant(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getMostActiveCoursesAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdoverviewapi.overviewOrgsMostActiveCoursesRetrieve(...args),
    getTenant(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getRegisteredUsersTotalAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdoverviewapi.overviewOrgsRegisteredUsersRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}

export function getActiveUsersTotalAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdoverviewapi.overviewOrgsActiveUsersRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}

export function getCredentialsIssuedAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdcredapi.credentialsOrgsUsersAssertionsOverTimeRetrieve(...args),
    getTenant(),
    getUserName(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getCourseCompletionRateAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdoverviewapi.overviewOrgsCoursesCompletionsRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}
