import React from "react";
import "./CopilotLauncher.css";
import {useDebounce} from "../../hooks/useDebounce";
import mentorLauncherIcon from "../../assets/images/mentor-launcher-icon.svg"
import $ from "jquery"

const CopilotLauncher = (props) => {
    const handleCopilotLaunch = () => {
        $('.mentor-launcher-block .user-prompt-input-field').on('focus', function(){
            $('.body .webpage-container').removeClass("no-padding-mobile")
            $('.body .right-content').addClass("show-mentor")
            $(".mentor-closer-block").removeClass("hidden")
            $(this).blur();
            const iframedCopilot =
                document.querySelector('#iframed-mentor');
            //const slug = getPageSlug();
            const data = {
                focusPrompt: 1,
            };
            const message = JSON.stringify(data);
            iframedCopilot.contentWindow?.postMessage(message, '*');
        })
    }
    useDebounce(handleCopilotLaunch, 200, [])
  return (
      <div className="w-layout-hflex mentor-launcher-block">
          <div className="form-block-28 w-form">
              <form method="get" name="wf-form-user-prompt" data-name="user-prompt" id="wf-form-user-prompt" prop-events-value-onsubmit="handleUserPromptFormSubmit" prop-events-names="onSubmit" className="form-18" data-wf-page-id="657af3ad1367da03b3b12528" data-wf-element-id="3aa1ab3c-e5d9-553b-2a02-a11b69fba335">
                  <div className="input-container">
                      <div className="user-input-field-block"><input className="user-prompt-input-field black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input" maxLength="256" name="name-3" data-name="Name 3" placeholder="Enter a Prompt Here" type="text" id="name-3"/>
                          <div className="div-block-637">
                              <div prop-events-value-onclick="handlePromptFileUploadBtnClick" prop-events-names="onClick" data-w-id="3aa1ab3c-e5d9-553b-2a02-a11b69fba33a" className="user-input-actions-container upload-file-input-action-container greyish-bg-in-darkmode all-whiten-img-on-hover-in-dark-mode">
                                  <div className="tooltip-2 tooltip-user-file-upload">Add Files (5 max, 10Mb each) Accepts pdf, txt, csv, etc.</div><img src="https://uploads-ssl.webflow.com/650305782bc61751e5765211/650305782bc61751e5765225_paperclip.svg" loading="lazy" alt="" className="image-140"/>
                              </div>
                              <div prop-events-value-onclick="handleMicrophoneBtnClick" prop-events-names="onClick" data-w-id="3aa1ab3c-e5d9-553b-2a02-a11b69fba33e" className="user-input-actions-container greyish-bg-in-darkmode user-input-microphone-btn"><img src="images/microphone-svgrepo-com.svg" loading="lazy" alt="" className="image-140 whiten-in-dark-mode"/>
                                  <div className="tooltip-2 tooltip-microphone">Use the microphone</div>
                              </div>
                              <div prop-events-value-onclick="handleAIAudioCallBtnClick" prop-events-names="onClick" data-w-id="3aa1ab3c-e5d9-553b-2a02-a11b69fba342" className="user-input-actions-container greyish-bg-in-darkmode user-input-audio-chat-btn"><img src="images/phone-calling-svgrepo-com.svg" loading="lazy" alt="" className="image-140 whiten-in-dark-mode ai-audio-chat-icon"/>
                                  <div className="tooltip-2 tooltip-microphone">Make AI Audio Chat</div>
                              </div>
                          </div>
                      </div>
                  </div><input type="submit" data-wait="Please wait..." className="submit-button w-button" value=""/>
                  <div prop-events-value-onclick="handleUserPromptFormSubmit" prop-events-names="onClick" className="input-submit-container"><img alt="" loading="lazy" src={mentorLauncherIcon} className="input-submit-icon whiten-in-dark-mode"/></div>
              </form>
              <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
              </div>
          </div>
      </div>
  );
};

export default CopilotLauncher;
