import React, { useContext, useEffect, useRef } from 'react';
import './Usermenu.css';
import { AppDataContext } from '@iblai/ibl-web-react-common';
import { getImageUrl, handleLogout } from '../../hooks/helpers';
import { useDebounce } from '../../hooks/useDebounce';
import $ from 'jquery';

const Usermenu = (props) => {
  const { appData, setAppData } = useContext(AppDataContext);

  const dropdownRef = useRef();

  const handleUserMenuClick = () => {
    $('.div-block-2 .profile_menu_trigger').on('click', function () {
      $('.prof_menu_popup').show();
    });
    //HIDING DROPDOWN WHEN CLICKING ELSEWHERE
    $(document).on('click', function (e) {
      if (
        $('.div-block-2 .prof_menu_popup:visible') &&
        !$(e.target).closest('.div-block-2 .profile_menu_trigger').length
      ) {
        $('.div-block-2 .prof_menu_popup:visible').hide();
      }
    });
  };

  useDebounce(handleUserMenuClick, 200, []);

  return (
    <div className="div-block-2">
      <div
        className="profile_menu_trigger"
        onClick={() => handleUserMenuClick()}
      >
        <div className="text-block-130 username-t">
          {appData?.userData?.user_nicename}
        </div>
        <img
          alt=""
          className="image-4"
          loading="lazy"
          src={getImageUrl(appData?.userData?.profile_image)}
        />
        <div className="text-block fa_icon arrow"></div>
        <div className="div-block-164 profile">
          <div className="div-block-165">
            <div className="text-block-104">
              {appData?.userData?.user_fullname}
            </div>
            <div className="text-block-104 em">
              {appData?.userData?.user_email}
            </div>
          </div>
          <a className="link-block-11 w-inline-block" href="#">
            <div className="text-block-106">Overview</div>
          </a>
          <a className="link-block-11 w-inline-block" href="#">
            <div className="text-block-106">Profile</div>
          </a>
          <a className="link-block-11 w-inline-block" href="#">
            <div className="text-block-106">Log Out</div>
          </a>
        </div>
      </div>
      <div className="prof_menu_popup" ref={dropdownRef}>
        <div className="div-block-195">
          <div className="link-2-user_info">
            <div className="text-block-168">
              {appData?.userData?.user_nicename}
            </div>
            <div className="text-block-168">
              {appData?.userData?.user_email}
            </div>
          </div>
        </div>
        {/*<div className="div-block-195 show-tenants">
          <div className="html-embed-6 w-embed">
            <svg
              className="lucide lucide-building-2"
              fill="none"
              height={18}
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              width={18}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"></path>
              <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
              <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"></path>
              <path d="M10 6h4"></path>
              <path d="M10 10h4"></path>
              <path d="M10 14h4"></path>
              <path d="M10 18h4"></path>
            </svg>
          </div>
          <a className="link-2" href="#">
            IBL
          </a>
          <div className="text-block fa_icon arrow mgl-auto"></div>
        </div>
        <div className="div-block-195 user-tenants">
          <a className="link-2 mg-l-30" href="#">
            Nexa
          </a>
        </div>*/}
        <div className="div-block-195">
          <div className="html-embed-6 w-embed">
            <svg
              fill="none"
              height="18px"
              viewBox="-0.5 0 25 25"
              width="18px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22.3201C17.5228 22.3201 22 17.8429 22 12.3201C22 6.79722 17.5228 2.32007 12 2.32007C6.47715 2.32007 2 6.79722 2 12.3201C2 17.8429 6.47715 22.3201 12 22.3201Z"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></path>
              <path
                d="M2 12.3201H22"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></path>
              <path
                d="M12 22.3201C13.933 22.3201 15.5 17.8429 15.5 12.3201C15.5 6.79722 13.933 2.32007 12 2.32007C10.067 2.32007 8.5 6.79722 8.5 12.3201C8.5 17.8429 10.067 22.3201 12 22.3201Z"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></path>
            </svg>
          </div>
          <a className="link-2" href="#">
            View Live
          </a>
        </div>
        <div className="div-block-195 last">
          <div className="html-embed-6 w-embed">
            <svg
              className="lucide lucide-log-out"
              fill="none"
              height={18}
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              width={18}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1={21} x2={9} y1={12} y2={12}></line>
            </svg>
          </div>
          <a className="link-2" onClick={() => handleLogout()}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default Usermenu;
