import {useContext, useEffect, useState} from 'react';
import {
    getUserEarnedSkillsAPI,
    getAverageGradeAPI,
    getMostActiveCoursesAPI,
    getRegisteredUsersTotalAPI,
    getActiveUsersTotalAPI,
    getCredentialsIssuedAPI,
    getCourseCompletionRateAPI,
} from './utils/overview';
import {getFormattedPredictiveDataset, getPredictiveData} from "./utils/predictive";
import {AppDataContext} from "@iblai/ibl-web-react-common";
import _ from "lodash";  // Replace with the correct path

const useOverview = () => {
    const {appData, setAppData} = useContext(AppDataContext);
    const [earnedSkills, setEarnedSkills] = useState([]);
    const [earnedSkillsLoaded, setEarnedSkillsLoaded] = useState(false);

    const [averageGrade, setAverageGrade] = useState({});
    const [averageGradeLoaded, setAverageGradeLoaded] = useState(false);

    const [predictedAverageGrade, setPredictedAverageGrade] = useState({});
    const [predictedAverageGradeLoaded, setPredictedAverageGradeLoaded] = useState(false);

    const [mostActiveCourses, setMostActiveCourses] = useState([]);
    const [mostActiveCoursesLoaded, setMostActiveCoursesLoaded] = useState(false);

    const [predictedMostActiveCourses, setPredictedMostActiveCourses] = useState([]);
    const [predictedMostActiveCoursesLoaded, setPredictedMostActiveCoursesLoaded] = useState(false);

    const [registeredUsersTotal, setRegisteredUsersTotal] = useState({});
    const [registeredUsersTotalLoaded, setRegisteredUsersTotalLoaded] = useState(false);

    const [activeUsersTotal, setActiveUsersTotal] = useState({});
    const [activeUsersTotalLoaded, setActiveUsersTotalLoaded] = useState(false);

    const [credentialsIssued, setCredentialsIssued] = useState([]);
    const [credentialsIssuedLoaded, setCredentialsIssuedLoaded] = useState(false);

    const [predictedCredentialsIssued, setPredictedCredentialsIssued] = useState([]);
    const [predictedCredentialsIssuedLoaded, setPredictedCredentialsIssuedLoaded] = useState(false);

    const [courseCompletionRate, setCourseCompletionRate] = useState({});
    const [courseCompletionRateLoaded, setCourseCompletionRateLoaded] = useState(false);

    const [predictedCourseCompletionRate, setPredictedCourseCompletionRate] = useState({});
    const [predictedCourseCompletionRateLoaded, setPredictedCourseCompletionRateLoaded] = useState(false);

    const [predictedRegisteredUsers, setPredictedRegisteredUsers] = useState({})
    const [predictedRegisteredUsersLoaded, setPredictedRegisteredUsersLoaded] = useState(false)

    const [predictedActiveUsers, setPredictedActiveUsers] = useState({})
    const [predictedActiveUsersLoaded, setPredictedActiveUsersLoaded] = useState(false)

    const is_ai_enabled = process.env.REACT_APP_IBL_ENABLE_AI !== 'false';


    const handleSetPredictiveRegisteredUserData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const predRegisteredUsers = _.sum(Object.values(predictedData))
        setPredictedRegisteredUsers({
            data: predictedData,
            total: predRegisteredUsers,
            meta: {
                nm_percent: 0,
                ny_percent: 0,
                n3y_percent: 0,
            }
        })
        setPredictedRegisteredUsersLoaded(true)
    }
    const handleSetPredictiveActiveUsersData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const totalSum = _.sum(Object.values(predictedData))
        setPredictedActiveUsers({
            data: predictedData,
            total: totalSum,
            meta: {
                nm_percent: 0,
                ny_percent: 0,
                n3y_percent: 0,
            }
        })
        setPredictedActiveUsersLoaded(true)
    }

    const handleSetPredictiveCourseCompletionData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const totalSum = _.sum(Object.values(predictedData))
        setPredictedCourseCompletionRate({
            data: predictedData,
            total: totalSum,
            meta: {
                nm_percent: 0,
                ny_percent: 0,
                n3y_percent: 0,
            }
        })
        setPredictedCourseCompletionRateLoaded(true)
    }

    const handleSetPredictiveCredentialsIssuedData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const totalSum = _.sum(Object.values(predictedData))
        setPredictedCredentialsIssued({
            data: predictedData,
            total: totalSum,
            meta: {
                nm_percent: 0,
                ny_percent: 0,
                n3y_percent: 0,
            }
        })
        setPredictedCredentialsIssuedLoaded(true)
    }

    const handleSetPredictiveAverageGradeData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const average = _.ceil(_.sum(Object.values(predictedData)) / (Object.values(predictedData).length))
        setPredictedAverageGrade({
            data: {
                average: average
            }
        })
        setPredictedAverageGradeLoaded(true)
    }

    useEffect(() => {

        getRegisteredUsersTotalAPI((data) => {
            setRegisteredUsersTotal(data);
            setRegisteredUsersTotalLoaded(true);
            if (is_ai_enabled) {
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("registered_users", data?.data), handleSetPredictiveRegisteredUserData, data?.total)
            }
        });

        getUserEarnedSkillsAPI((data) => {
            setEarnedSkills(data);
            setEarnedSkillsLoaded(true);
        });

        getAverageGradeAPI((data) => {
            setAverageGrade(data);
            setAverageGradeLoaded(true);
            if (is_ai_enabled) {
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("average_grade", data?.data), handleSetPredictiveAverageGradeData, data?.total)
            }
        });

        getMostActiveCoursesAPI((data) => {
            setMostActiveCourses(data);
            setMostActiveCoursesLoaded(true);
            //getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("most_active_courses", data?.data), handleSetPredictiveMostActiveCoursesData, data?.total)
        });


        getActiveUsersTotalAPI((data) => {
            setActiveUsersTotal(data);
            setActiveUsersTotalLoaded(true);
            if (is_ai_enabled){
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("active_users", data?.data), handleSetPredictiveActiveUsersData, data?.total)
            }
        });

        getCredentialsIssuedAPI((data) => {
            setCredentialsIssued(data);
            setCredentialsIssuedLoaded(true);
            if( is_ai_enabled){
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("course_completion", data?.data), handleSetPredictiveCredentialsIssuedData, data?.total)
            }
        });

        getCourseCompletionRateAPI((data) => {
            setCourseCompletionRate(data);
            setCourseCompletionRateLoaded(true);
            if (is_ai_enabled){
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("course_completion", data?.data), handleSetPredictiveCourseCompletionData, data?.total)
            }
        });
    }, []);

    return {
        earnedSkills,
        earnedSkillsLoaded,
        averageGrade,
        averageGradeLoaded,
        mostActiveCourses,
        mostActiveCoursesLoaded,
        registeredUsersTotal,
        registeredUsersTotalLoaded,
        activeUsersTotal,
        activeUsersTotalLoaded,
        credentialsIssued,
        credentialsIssuedLoaded,
        courseCompletionRate,
        courseCompletionRateLoaded,
        predictedRegisteredUsers,
        predictedRegisteredUsersLoaded,
        predictedActiveUsersLoaded,
        predictedActiveUsers,
        predictedCourseCompletionRate,
        predictedCourseCompletionRateLoaded,
        predictedCredentialsIssuedLoaded,
        predictedCredentialsIssued,
        predictedAverageGrade,
        predictedAverageGradeLoaded
    }


};

export default useOverview;
