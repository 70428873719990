import React, { useEffect, useState, useRef } from 'react';

import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';
import './InvitationsViewContent.css';
import {
  getInvitations,
  postInvite,
  postRedeem,
  getCourseProgram,
} from '../../../hooks/utils/invitations';
import { useDebounce } from '../../../hooks/useDebounce';
import { getImageUrl, getTableSizes } from '../../../hooks/helpers';
import { Link } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination/TablePagination';
import TableSizeDropdown from '../../../components/TableSizeDropdown/TableSizeDropdown';

const imageUrl = getImageUrl('');
const TABLE_SIZES = getTableSizes();

const InvitationsViewContent = () => {
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const [invitations, setInvitations] = useState([]);
  const [invitationsLoaded, setInvitationsLoaded] = useState(false);
  const [isInviteActive, setIsInviteActive] = useState(false);
  const [isRedeemActive, setIsRedeemActive] = useState(false);
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [courseOrProgram, setCourseOrProgram] = useState('');
  const [courseOrProgramOptions, setCourseOrProgramOptions] = useState([]);
  const [optionType, setOptionType] = useState('');
  const [optionID, setOptionID] = useState('');
  const fileInputRef = useRef(null);
  const [invitationData, setInvitationData] = useState([]);
  const [csvType, setCsvType] = useState('');
  const [fileName, setFileName] = useState('');
  const [pagination, setPagination] = useState({});
  const [numberOfInvites, setNumberOfInvites] = useState();
  const [source, setSource] = useState('');
  const [availableInvites, setAvailableInvites] = useState('');

  const handleInviteBtnClick = () => {
    setIsInviteActive(!isInviteActive);
  };

  const handleSubmitInvite = () => {
    let data = {
      userNameOrEmail: userNameOrEmail,
      type: optionType,
      id: optionID,
      invitationData: invitationData,
      csvType: csvType,
      numberOfInvites: numberOfInvites,
    };
    postInvite(data, () => {
      handleInvitationsLoad();
      setIsInviteActive(false);
    });
  };

  const handleRedeemInvite = () => {
    let data = {
      userNameOrEmail: userNameOrEmail,
      source: source,
    };
    postRedeem(data, () => {
      handleInvitationsLoad();
      setIsRedeemActive(false);
    });
  };

  const handleInvitationsLoad = () => {
    setInvitationsLoaded(false);
    getInvitations(
      search,
      size,
      (data) => {
        setInvitations(data);

        setInvitationsLoaded(true);
        setPagination({
          currentPage: page,
          perPage: size,
          totalItems: data.count,
          totalPages: Math.ceil(data.count / size),
        });
        clearForm();
      },
      page
    );
  };

  const handleAvailableInvites = () => {
    getInvitations(
      '',
      1000,
      (data) => {
        calculateAvailableInvites(data);
      },
      1
    );
  };

  const calculateAvailableInvites = (invitations) => {
    if (Array.isArray(invitations.results)) {
      const availableInvites = invitations.results.filter(
        (invite) => !invite.email && !invite.username && invite.source
      );
      setAvailableInvites(availableInvites.length);
    }
  };

  const handleCourseProgramSearch = (value) => {
    setCourseOrProgram(value);
    if (value.length > 2) {
      getCourseProgram(value, (data) => {
        setCourseOrProgramOptions(data.results);
      });
    }
    if (value.length === 0) {
      setCourseOrProgramOptions([]);
    }
  };

  const optionSelect = (value, type, id) => {
    setCourseOrProgram(value);
    setOptionType(type);
    setOptionID(id);
    setCourseOrProgramOptions([]);
  };

  const handleCSVClick = () => {
    fileInputRef.current.click();
  };

  const clearForm = () => {
    setUserNameOrEmail('');
    setCourseOrProgram('');
    setOptionType('');
    setOptionID('');
    setInvitationData([]);
    setCsvType('');
    setFileName('');
    setNumberOfInvites('');
    setSource('');
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFileName(selectedFile.name);
      let reader = new FileReader();

      reader.onload = function (e) {
        let content = e.target.result;
        let lines = content
          .split(/[\r\n]+/)
          .filter((line) => line.trim() !== '');

        // Determine the separator (comma or semicolon)
        let separator = lines[0].includes(';') ? ';' : ',';

        // Determine whether it's a course, program, or platform CSV from the header
        let isCourseCSV = lines[0].includes('course');
        let isProgramCSV = lines[0].includes('program');
        let isPlatformCSV = !isCourseCSV && !isProgramCSV;

        let parsedData = lines.slice(1).map((line) => {
          let [userIdentifier, id] = line.split(separator);
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          let entry = {};

          if (emailRegex.test(userIdentifier)) {
            entry.email = userIdentifier;
          } else {
            entry.username = userIdentifier;
          }
          if (isCourseCSV) {
            entry.course_id = id;
            if (!csvType) setCsvType('course');
          } else if (isProgramCSV) {
            entry.program_key = id; // Adjusted to program_id for consistency
            if (!csvType) setCsvType('program');
          } else {
            entry.platform_key = localStorage.getItem('tenant');
            if (!csvType) setCsvType('platform');
          }
          return entry;
        });
        setInvitationData(parsedData);
      };

      reader.onerror = function (e) {
        console.error('Error reading file', e);
      };

      reader.readAsText(selectedFile);
    }
  };

  useDebounce(handleInvitationsLoad, 300, [search, size, page]);
  useDebounce(handleAvailableInvites, 300);

  return (
    <>
      <div
        className={`popup_div-nm_popup invite-popup ${isInviteActive ? 'active' : ''}`}
      >
        <div className="popup_cont-sk">
          <div className="hf_popup">
            <div className="div-block-147 new_msg">
              <div className="text-block-135 mg-0">
                Invite User
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div className="div-block-228">
              <div className="form-block-7 w-form">
                <form
                  data-name="Email Form 5"
                  data-wf-element-id="1652ea72-9a5b-2d7f-eb57-581687993f77"
                  data-wf-page-id="657aff678982edbdb39ddab6"
                  id="email-form-5"
                  method="get"
                  name="email-form-5"
                >
                  <label className="text-block-139-bd-f-soc" htmlfor="name-2">
                    User *
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter a username or an email"
                    type="text"
                    value={userNameOrEmail}
                    onChange={(e) => setUserNameOrEmail(e.target.value)}
                  />
                  <label className="text-block-139-bd-f-soc" htmlfor="name-2">
                    Course / Program
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter a course or a program"
                    type="text"
                    value={courseOrProgram} // Set input value from state
                    onChange={(e) => handleCourseProgramSearch(e.target.value)}
                  />
                  <div className="input-w-dropdown">
                    <div className="course-program-dropdown">
                      {courseOrProgramOptions.map((option) => (
                        <div
                          key={option.id}
                          className="option"
                          data-id={
                            option._index ===
                            'ibl-dm.public.dl_catalog_app_course'
                              ? option._source.course_id
                              : option._source.program_id
                          }
                          data-type={option._index}
                          onClick={() =>
                            optionSelect(
                              option._source.name,
                              option._index,
                              option._index ===
                                'ibl-dm.public.dl_catalog_app_course'
                                ? option._source.course_id
                                : option._source.program_id
                            )
                          }
                        >
                          {option._source.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  <label className="text-block-139-bd-f-soc" htmlfor="name-3">
                    Import CSV
                  </label>
                  <div className="div-block-307">
                    <div
                      className="div-block-207 first active follow-user"
                      onClick={handleCSVClick}
                    >
                      <div className="text-block-111 red-text cf">
                        Choose file
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      accept=".csv" // Restrict to CSV files
                    />
                    <div className="text-block-167">
                      {fileName || 'No file chosen'}
                    </div>
                  </div>
                  <label className="text-block-139-bd-f-soc" htmlfor="name-2">
                    Number of Invites
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter number of invites"
                    type="number"
                    value={numberOfInvites}
                    onChange={(e) => setNumberOfInvites(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div className="div-block-147 new_msg">
              <div className="div-block-264 f-w">
                <div
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
                  onClick={() => {
                    setIsInviteActive(false);
                    clearForm();
                  }}
                >
                  <div className="text-block-111 red-text cf">Cancel</div>
                </div>
                <div
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
                  onClick={handleSubmitInvite}
                >
                  <div className="text-block-111 red-text cf">Invite</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`popup_div-nm_popup invite-popup ${isRedeemActive ? 'active' : ''}`}
      >
        <div className="popup_cont-sk">
          <div className="hf_popup">
            <div className="div-block-147 new_msg">
              <div className="text-block-135 mg-0">
                Redeem Invite
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div className="div-block-228">
              <div className="form-block-7 w-form">
                <div
                  data-name="Email Form 5"
                  data-wf-element-id="dcaa5443-fa45-544f-1705-04f761d8c6c4"
                  data-wf-page-id="657aff678982edbdb39ddab6"
                  id="email-form-5"
                  method="get"
                  name="email-form-5"
                >
                  <label className="text-block-139-bd-f-soc" htmlfor="name-3">
                    User *
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter a username or an email"
                    type="text"
                    value={userNameOrEmail}
                    onChange={(e) => setUserNameOrEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="div-block-147 new_msg">
              <div className="div-block-264 f-w">
                <div
                  className="div-block-207 first active follow-user redeem-btn alt-btn cancel-redeem"
                  onClick={() => {
                    setIsRedeemActive(false);
                    clearForm();
                  }}
                >
                  <div className="text-block-111 red-text cf">Cancel</div>
                </div>
                <div
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
                  onClick={handleRedeemInvite}
                >
                  <div className="text-block-111 red-text cf">Redeem</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">
            INVITES
            <br />
          </div>
        </div>
        <div className="div-block-308">
          <div
            className="div-block-207 first active follow-user invite-btn"
            onClick={handleInviteBtnClick}
          >
            <div className="text-block-111">Invite</div>
          </div>
        </div>
      </div>
      <div className="p-row over-row" is-react-component="PlatformInvitations">
        <div className="table_cont ns w_o full-w">
          <div className="div-block-202 w_bg nbg w_m_w">
            <div className="div-block-235">
              <div className="sm available">
                Available Invites: {availableInvites}
              </div>
              <div className="sm available"></div>
            </div>
            <div className="div-block-169">
              <div className="div-block-171">
                <div className="sm">Show</div>
                <div className="form-block w-form">
                  <TableSizeDropdown setSize={setSize} setPage={setPage} />
                </div>
                <div className="sm">entries</div>
              </div>
            </div>
            <div className="div-block-217 ovt n_w">
              <div className="table">
                <div className="th">
                  <div className="tc _10">
                    <div className="sm">#</div>
                  </div>
                  <div className="tc _40">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Username</div>
                    </div>
                  </div>
                  <div className="tc _40">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Email</div>
                    </div>
                  </div>
                  <div className="tc _10">
                    <div className="w_sort">
                      <div className="text-block-134 fa_icon sort"></div>
                      <div className="sm">Active</div>
                    </div>
                  </div>
                  <div className="tc _10"></div>
                </div>
                {!invitationsLoaded ? (
                  <IBLSpinLoader
                    color={'#FFF'}
                    size={20}
                    containerHeight={'200px'}
                  />
                ) : (
                  <>
                    {Array.isArray(invitations.results) &&
                    invitations.results.length > 0 ? (
                      invitations.results.map((invitation, index) => (
                        <>
                          <div className="tr" key={index}>
                            <div className="tc tcell _10">
                              <div className="m">{index + 1}</div>
                            </div>
                            <div className="tc tcell _40">
                              <img
                                src={imageUrl}
                                loading="lazy"
                                alt=""
                                className="r-i"
                              />
                              <Link
                                to={`/per-learner/learners/${invitation?.username}`}
                                className="tl-2 np"
                              >
                                <span className="nb">
                                  {invitation?.username || '-'}
                                </span>
                                <span className="text-span-7"></span>
                              </Link>
                            </div>
                            <div className="tc tcell _40">
                              <div className="m">{invitation?.email}</div>
                            </div>
                            <div className="tc tcell w_a _10">
                              <div className="sm">
                                <div
                                  className={`text-block fa_icon ${invitation.active ? 'check-circle' : 'close'}`}
                                >
                                  {invitation.active ? '' : ''}{' '}
                                  {/* Replace '' and '' with the correct character codes for your icons */}
                                </div>
                              </div>
                            </div>
                            <div className="tc _10">
                              {invitation.source &&
                                !invitation.email &&
                                !invitation.username && (
                                  <div
                                    data-source={invitation.source}
                                    data-name="redeem"
                                    className="redeem-btn popup-toggle"
                                    data-type="platform"
                                    onClick={() => {
                                      setIsRedeemActive(true);
                                      setSource(invitation.source);
                                    }}
                                  >
                                    {' '}
                                    Redeem{' '}
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <EmptyTableDataInfographic />
                    )}
                    {
                      <TablePagination
                        setPage={setPage}
                        page={page}
                        pagination={pagination}
                      />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvitationsViewContent;
