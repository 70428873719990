import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import $ from "jquery"
import {useDebounce} from "../../hooks/useDebounce";

const Sidebar = () => {
    const [activePage, setActivePage] = useState("");
    const location = useLocation();
    const [clickedIndex, setClickedIndex] = useState(null);

    const handleMenuClick = (index) => {
        setClickedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleSubMenuClick = () =>{
        $('.submenu-item.web-sub a').on('click', function(){
            const subMenusEl = $(this).closest(".submenu-item.web-sub").find('.submenu.w_menu')
            if(subMenusEl.is(":visible")){
                subMenusEl.hide()
            }else{
                subMenusEl.show()
            }
        })
    }

    useDebounce(handleSubMenuClick, 300, [])

    useEffect(() => {
        const submenuItems = document.querySelectorAll('.submenu .w_menu');

        submenuItems.forEach(item => {
            item.addEventListener('click', () => {
                submenuItems.forEach(submenuItem => {
                    submenuItem.classList.remove('active');
                });
                item.classList.add('active');
            });
        });
    }, []);

    useEffect(() => {
        const pathname = location.pathname.substring(1).toUpperCase();
        setActivePage(pathname);
    }, [location]);

    const menuItems = [
        {
            name: "overview",
            icon: "",
            label: "AUDIENCE",
            submenu: [
                {label: "REGISTERED USERS", page: "REGISTERED USERS", url: "/audience/registered-users"},
                {label: "ACTIVE USERS", page: "ACTIVE USERS", url: "/audience/active-users"},
                {label: "ENROLLMENTS", page: "ENROLLMENTS", url: "/audience/enrollments"},
                {label: "UNENROLLMENTS", page: "UNENROLLMENTS", url: "/audience/unenrollments"}
            ]
        },
        {
            name: "studio",
            icon: "",
            label: "STUDIO",
            submenu: [
                {
                    label: "AUTHORING",
                    submenu : [
                        {label: "Courses", page: "Courses", url: "/studio/authoring/courses"},
                        {label: "Programs", page: "Programs", url: "/studio/authoring/programs"}
                    ]
                },
                {label: "SETTINGS", page: "Settings", url: "/studio/settings"}


            ],
        },

        {
            name: "engagement",
            icon: "",
            label: "ENGAGEMENT",
            submenu: [
                {label: "COURSE COMPLETION", page: "COURSE COMPLETION", url: "/engagement/course-completion"},
                {label: "TIME SPENT", page: "TIME SPENT", url: "/engagement/time-spent"},
            ]
        },
        {
            name: "performance",
            icon: "",
            label: "PERFORMANCE",
            submenu: [
                {label: "GRADING", page: "GRADING", url: "/performance/grading"},
                {label: "CREDENTIALS", page: "CREDENTIALS", url: "/performance/credentials"}
            ]
        },
        /*{
            name: "financial",
            icon: "",
            label: "FINANCIAL",
            submenu: [
                {label: "Summary", page: "Summary", url: "/financial/summary"},
                {label: "Revenue", page: "Revenue", url: "/financial/revenue"},
                {label: "Products", page: "Products", url: "/financial/products"},
                {label: "Customers", page: "Customers", url: "/financial/customers"}
            ]
        },*/
        /*{
            name: "web",
            icon: "",
            label: "WEB",
            submenu: [
                {label: "Dashboard", page: "Dashboard", url: "web/dashboard.html"},
                {
                    label: "VISITORS",
                    submenu: [
                        {label: "Overview", page: "Overview", url: "web/visitors/overview.html"},
                        {label: "Visits Log", page: "Visits Log", url: "web/visitors/visits-log.html"},
                        {label: "Real-time", page: "Real-time", url: "web/visitors/realt-time.html"},
                        {label: "Locations", page: "Locations", url: "web/visitors/locations.html"},
                        {label: "Devices", page: "Devices", url: "web/visitors/devices.html"},
                        {label: "Software", page: "Software", url: "web/visitors/software.html"},
                        {label: "Times", page: "Times", url: "web/visitors/times.html"},
                    ]
                },
                {
                    label: "BEHAVIOUR",
                    submenu: [
                        {label: "Pages", page: "Pages", url: "web/behavior/pages.html"},
                        {label: "Entry Pages", page: "Entry Pages", url: "web/behavior/entry-pages.html"},
                        {label: "Exit Pages", page: "Exit Pages", url: "web/behavior/exit-pages.html"},
                        {label: "Page Titles", page: "Page Titles", url: "web/behavior/page-titles.html"},
                        {label: "Site Search", page: "Site Search", url: "web/behavior/site-search.html"},
                        {label: "Outlinks", page: "Outlinks", url: "web/behavior/outlinks.html"},
                        {label: "Engagement", page: "Engagement", url: "web/behavior/engagement.html"},
                        {label: "Transitions", page: "Transitions", url: "web/behavior/transitions.html"},
                        {label: "Performance", page: "Performance", url: "web/behavior/performance.html"},
                    ]
                },
                {
                    label: "ACQUISITION",
                    submenu: [
                        {label: "All Channels", page: "All Channels", url: "web/acquisition/all-channels.html"},
                        {
                            label: "Search Engines & Keywords",
                            page: "Search Engines & Keywords",
                            url: "web/acquisition/search-engines-keywords.html"
                        },
                        {label: "Websites", page: "Websites", url: "web/acquisition/websites.html"},
                        {
                            label: "Social Networks",
                            page: "Social Networks",
                            url: "web/acquisition/social-networks.html"
                        },
                        {label: "Campaigns", page: "Campaigns", url: "web/acquisition/campaigns.html"},
                        {
                            label: "Campaign URL Builder",
                            page: "Campaign URL Builder",
                            url: "web/acquisition/campaign-url-builder.html"
                        },
                    ]
                },
                {
                    label: "GOALS",
                    submenu: [
                        {label: "Overview", page: "Overview", url: "web/goals/overview.html"},
                    ]
                }
            ]
        },*/
        {
            name: "per-learner",
            icon: "",
            label: "PER LEARNER",
            submenu: [
                //{label: "Summary", page: "Summary", url: "/per-learner/summary"},
                {label: "LEARNERS", page: "Learners", url: "/per-learner/learners"},
                //{label: "INVITATIONS", page: "Invitations", url: "/per-learner/invitations"}
            ]
        }
    ];


    return (
        <div className="sidebar">
            <div className="wrapper">
                <div data-name="overview" className="menu-wr">
                    <NavLink to="/" className="link-block-19 w-inline-block">
                        <div className="menu-option">
                            <div className="menu-icon">
                                <img
                                    src="https://assets-global.website-files.com/657af3ad1367da03b3b12527/657af3ad1367da03b3b1253e_62b33c1182cd9cbdcc8a0da1_62b1ddfcac0d91564ba2a3eb_ibl_logo.png"
                                    loading="lazy"
                                    alt=""
                                    className="icon"
                                />
                                <img
                                    src="https://assets-global.website-files.com/657af3ad1367da03b3b12527/657af3ad1367da03b3b12536_62bc49316fcb819a85489090_62b33c1182cd9cbdcc8a0da1_62b1ddfcac0d91564ba2a3eb_ibl_logo.png"
                                    loading="lazy"
                                    alt=""
                                    className="icon_h"
                                />
                            </div>
                            <div>
                                <div className="text-block-107">OVERVIEW</div>
                            </div>
                        </div>
                    </NavLink>
                </div>

                {menuItems.map((menuItem, index) => (
                    <div className="menu-wr" key={index}>
                        <a
                            className="link-block-13 w-inline-block"
                            data-name={menuItem.name}
                            href="#"
                            onClick={() => handleMenuClick(index)}
                        >
                            <div className="menu-option">
                                <div className="menu-icon">
                                    <div className="text-block fa_icon">{menuItem.icon}</div>
                                </div>
                                <div>
                                    <div className="text-block-107">{menuItem.label}</div>
                                </div>
                                <div className="submenu_arrow">
                                    <div className="text-block fa_icon up_arrow"></div>
                                    <div
                                        className={`text-block fa_icon down_arrow ${clickedIndex === index ? 'active' : ''}`}>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div className={`submenu ${clickedIndex === index ? 'clicked' : ''}`}>
                            {menuItem.submenu.map((subItem, subIndex) => (
                                <React.Fragment key={subIndex}>
                                    {subItem.label && !subItem.submenu && (
                                        <div className={`submenu-item ${activePage === subItem.page ? 'active' : ''}`}
                                             key={subIndex}>
                                            <NavLink className="link-block-12 w-inline-block" to={subItem.url}>
                                                <div className="text-block-108">{subItem.label}</div>
                                            </NavLink>
                                        </div>
                                    )}
                                    {subItem.submenu && (
                                        <div className="submenu-item web-sub">
                                            <a href="#" className="link-block-12 w_sb w-inline-block">
                                                <div className="text-block-108">{subItem.label}</div>
                                                <div className="text-block fa_icon down_arrow"></div>
                                            </a>
                                            {
                                                Array.isArray(subItem?.submenu) && subItem?.submenu.length > 0 &&
                                                <div className="submenu w_menu">
                                                    {subItem?.submenu?.map((subMenuItem, index) => (
                                                        <div key={index}>
                                                            <div className="submenu-item web-sub">
                                                                <NavLink to={subMenuItem.url}
                                                                         className="link-block-12 w-inline-block">
                                                                    <div
                                                                        className="text-block-108">{subMenuItem.label}</div>
                                                                </NavLink>
                                                            </div>

                                                        </div>
                                                    ))}

                                                </div>
                                            }

                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
