import { useEffect, useRef } from "react";
import Chart from "chart.js";

const DescriptiveLineGraphCanvas = ({ data, chartId, isPredictive=false }) => {
  const chartRef = useRef(null);


  useEffect(() => {
    if (data && chartId) {
      if (chartRef.current) {
        // Destroy the existing Chart instance
        chartRef.current.destroy();
      }
      try{
        fillUpLineChart(data, chartId);

      } catch (e){

      }
    }
  }, [data, chartId]);

  const fillUpLineChart = (chartData, id) => {
    const ctx = document.getElementById(id).getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, !isPredictive ? "rgba(82, 123, 205, 0.36)" : "rgba(119, 247, 93, 0.36)");
    gradient.addColorStop(0.7, "#080808");

    let xValues = [];
    let yValues = [];

    Object.keys(data.data).forEach(function (date) {
      xValues.push(date);
      yValues.push(data.data[date]);
    });

    chartRef.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: xValues,
        datasets: [
          {
            fill: true,
            fillColor: "red",
            pointRadius: 4,
            borderColor: !isPredictive ? "#527bcd" : "#77f75d",
            pointBackgroundColor: !isPredictive ?  "#527bcd" : "#77f75d",
            data: yValues,
            backgroundColor: gradient,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "y = x * 2 + 7",
          fontSize: 16,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true, // Ensure the y-axis starts from zero
              },
            },
          ],
        },

      },
    });
  };

  return <canvas id={chartId} style={{ height: 220, width: "auto" }} />;
};
export default DescriptiveLineGraphCanvas;
