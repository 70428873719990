import React from 'react';
import './notifications.css';
import closeLogoImg from '../../assets/images/close-1_1close-1.png';
import xImg from '../../assets/images/x.svg';
import infoImg from '../../assets/images/information_1information.png';
import checkImg from '../../assets/images/check-1_1check-1.png';
import PropTypes from 'prop-types';

const Notifications = ({ headerMsg, displayMsg, type, onClose }) => {
  const typeImageMap = {
    error: closeLogoImg,
    info: infoImg,
    success: checkImg,
  };
  return (
    <div className="notifications-block">
      <div className={'w-layout-hflex notification-element ' + type}>
        <div className="w-layout-hflex notification-header-block">
          <img
            src={typeImageMap[type]}
            loading="lazy"
            alt=""
            className="notification-header-block-icon"
          />
          <div className="notification-header-title">{headerMsg}</div>
        </div>
        <div className="notification-body">
          <p className="notification-body-parag">{displayMsg}</p>
        </div>
        <div className="w-layout-hflex notification-close">
          <img
            onClick={() => onClose()}
            src={xImg}
            loading="lazy"
            alt=""
            className="notification-close-icon"
          />
        </div>
      </div>
      {/* <div className="w-layout-hflex notification-element error">
                <div className="w-layout-hflex notification-header-block"><img src={closeLogoImg}
                                                                               loading="lazy" alt=""
                                                                               className="notification-header-block-icon"/>
                    <div className="notification-header-title">Credentials incorrect</div>
                </div>
                <div className="notification-body">
                    <p className="notification-body-parag">Your Email adress / Password credentials are
                        incorrect.</p>
                </div>
                <div className="w-layout-hflex notification-close"><img src={xImg} loading="lazy" alt=""
                                                                        className="notification-close-icon"/></div>
            </div>
            <div className="w-layout-hflex notification-element info">
                <div className="w-layout-hflex notification-header-block"><img
                    src={infoImg} loading="lazy" alt=""
                    className="notification-header-block-icon"/>
                    <div className="notification-header-title">Login Completed</div>
                </div>
                <div className="notification-body">
                    <p className="notification-body-parag">Platform initializing, you&#x27;ll be redirected
                        shortly...</p>
                </div>
                <div className="w-layout-hflex notification-close"><img src={xImg} loading="lazy" alt=""
                                                                        className="notification-close-icon"/></div>
            </div>
            <div className="w-layout-hflex notification-element success">
                <div className="w-layout-hflex notification-header-block"><img src={checkImg}
                                                                               loading="lazy" alt=""
                                                                               className="notification-header-block-icon"/>
                    <div className="notification-header-title">Login Successful With Google</div>
                </div>
                <div className="notification-body">
                    <p className="notification-body-parag">Platform initializing, you&#x27;ll be redirected
                        shortly...</p>
                </div>
                <div className="w-layout-hflex notification-close"><img src={xImg} loading="lazy" alt=""
                                                                        className="notification-close-icon"/></div>
            </div> */}
    </div>
  );
};

Notifications.propTypes = {
  headerMsg: PropTypes.string.isRequired,
  displayMsg: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'error']).isRequired,
};

export default Notifications;
