import Descriptivelinechart from "../Descriptivelinechart/Descriptivelinechart";
import Linechartgraph from "../Linechartgraph/Linechartgraph";
import Coursecontentcompletiontable from "../Coursecontentcompletiontable/Coursecontentcompletiontable";
import Coursetable from "../Coursetable/Coursetable";
import Courseconentcompletionrow from "../Courseconentcompletionrow/Courseconentcompletionrow";
import Coursecontentpagination from "../Coursecontentpagination/Coursecontentpagination";
import Predictivedatepills from "../Predictivedatepills/Predictivedatepills";
import Descriptivedatepills from "../Descriptivedatepills/Descriptivedatepills";
import Predictivelinechart from "../Predictivelinechart/Predictivelinechart";
import React, {useEffect, useState} from "react";
import "./Coursecompletion.css";
import useCourseCompletion from "../../hooks/useCourseCompletion";
import {randomLineChartGraphData} from "../../hooks/helpers";
import DescriptiveLineChartGraph from "../DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import {getCourseCompletionPerCourseAPI} from "../../hooks/utils/courseCompletion";

const Coursecompletion = (props) => {
  const [
    contentCompletionOverTime,
    contentCompletionOverTimeLoaded,
    courseCompletionPerCourse,
    courseCompletionPerCourseLoaded,
  ] = useCourseCompletion();

  /*const [tempLoadedState, setTempLoadedState] = useState(false)

  useEffect(() => {
    setTimeout(()=>{
      setTempLoadedState(true)
    }, 3000)
  }, []);*/
  return (
    <div
      react-hook="useCourseCompletion"
      react-hook-return="courseContentCompletionOverTimeTotal,courseContentCompletionOverTimeLoaded,courseContentCompletionOverTimeChangeClass,courseContentCompletionOverTimeChangeSign,courseContentCompletionOverTimeNumericalChange,courseContentCompletionOverTimePercentageChange,courseContentCompletionOverTimeChangeSymbol,courseContentCompletionOverTimeChartData,predictedCourseContentCompletionTotal,predictedCourseContentCompletionLoaded,predictedCourseContentCompletionChangeClass,predictedCourseContentCompletionChangeSign,predictedCourseContentCompletionNumericalChange,predictedCourseContentCompletionPercentageChange,predictedCourseContentCompletionChangeSymbol,predictedCourseContentCompletionChartData,courseContentCompletionSelect,courseContentCompletionSearch,courseContentCompletionTableData,courseContentCompletionCurrentPage,courseContentCompletionItemsPerPage,courseContentCompletionTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{width:"100%"}}>
          <DescriptiveLineChartGraph
              data={contentCompletionOverTime}
              dataLoaded={contentCompletionOverTimeLoaded}
              title={"Course Content Completion Over Time"}
              index={1}
          />
        </div>
        {/*<div className="div-block-204 r">
          <DescriptiveLineChartGraph
              data={randomLineChartGraphData()}
              dataLoaded={tempLoadedState}
              title={"Predicted Course Content Completion"}
              index={2}
              isPredictive={true}
          />
        </div>*/}
      </div>
      <Coursetable useSizeDropdown={false} tableHeaders={['#', "Course", "Registered Users", "Percentage"]} endPoint={getCourseCompletionPerCourseAPI} title={"Course Content Completion"}></Coursetable>

    </div>
  );
};

export default Coursecompletion;
