import React, { useEffect, useState } from 'react';
import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';
import iblLogo from '../../assets/images/ibl-logo-animated.gif';
// import googleLogo from '../../assets/images/google-color-svgrepo-com.svg'
// import appleLogo from '../../assets/images/apple-svgrepo-com.svg'
// import microsoftLogo from '../../assets/images/microsoft-svgrepo-com1.svg'
// import closeLogoImg from '../../assets/images/close-1_1close-1.png'
// import xImg from '../../assets/images/x.svg'
// import infoImg from '../../assets/images/information_1information.png'
// import checkImg from '../../assets/images/check-1_1check-1.png'
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick,
  removeSpaceFromText,
  resetLoginForm,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';
import { api } from '@iblai/ibl-web-react-common';

const Login = () => {
  const [passwordType, setPasswordType] = useState('password');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [mfeContext, setMfeContext] = useState(null);
  const [notifications, notify, onClose] = useNotification();

  const handleResetForm = () => {
    resetLoginForm();
  };

  const handleLoginSubmit = ({
    username,
    password,
    handleResetForm,
    notify,
  }) => {
    api.iblwebauth.login(
      {
        username,
        password,
      },
      (data) => {
        window.location.href = data['redirect_uri'];
      },
      (error) => {
        handleResetForm();
        notify('Credentials incorrect', error.responseJSON.message, 'error');
      }
    );
  };

  const formSubmit = () => {
    $('body form.auth-form .auth-submit-btn:not(.disabled)').on(
      'click',
      function (e) {
        e.preventDefault();
        let formIsInvalid = false;
        const formParent = $(this).closest('form');
        const actualFormLevel = parseInt(
          formParent.attr('data-auth-actual-level')
        );
        const usernameEmailField = $('form.auth-form input.username');
        const typedUserNameEmail = usernameEmailField.val();

        const userNameEmailTextError = usernameEmailField
          .closest('.auth-input-group')
          .find('.text-error');
        const passwordField = $('form.auth-form input.password');
        const typedPassword = passwordField.val();
        const passwordTextError = passwordField
          .closest('.auth-input-group')
          .find('.text-error');
        if (actualFormLevel === 1) {
          if (!!!removeSpaceFromText(typedUserNameEmail)) {
            //CHECKING IF EMPTY EMAIL/USERNAME FIELD
            formIsInvalid = true;
            usernameEmailField.addClass('invalid');
            userNameEmailTextError.text('This field is required');
            userNameEmailTextError.removeClass('hidden');
          }
          if (!!formIsInvalid) {
            //DON'T CONTINUE IF FORM INVALID
            return false;
          }
          //HIDE PREVIOUS FORM LEVEL & SHOW NEXT FORM LEVEL LAYOUT
          formParent.find('input').removeClass('invalid');
          formParent.find('.text-error').addClass('hidden');
          $(`form.auth-form .auth-input-group[data-auth-level=1]`).fadeOut(
            '',
            () => {
              $(this).removeClass('visible');
              formParent.attr('data-auth-actual-level', '2');
              $(`form.auth-form .auth-input-group[data-auth-level=2]`).addClass(
                'visible'
              );
            }
          );
        } else {
          if (!!!removeSpaceFromText(typedPassword)) {
            //CHECKING IF EMPTY PASSWORD FIELD
            formIsInvalid = true;
            passwordField.addClass('invalid');
            passwordTextError.text('This field is required');
            passwordTextError.removeClass('hidden');
          }
          if (!!formIsInvalid) {
            //DON'T CONTINUE IF FORM INVALID
            return false;
          }
          //SUBMISSION START
          formParent.find('input').removeClass('invalid');
          formParent.find('.text-error').addClass('hidden');
          const submitButtonLabelElement = $(this).find(
            '.auth-submit-btn-label'
          );
          submitButtonLabelElement.text('Submitting');
          $(this).addClass('disabled');
          $(this).find('.btn-spinner-block').removeClass('hidden');
          handleLoginSubmit({
            username: String(typedUserNameEmail).trim(),
            password: String(typedPassword).trim(),
            handleResetForm,
            notify,
          });
          //$('.notification-element.error').show("slide", {direction: "right"}, 200);
          // $('.notification-element.error').show();
          // setTimeout(() => {
          //     //$('.notification-element.info').show("slide", {direction: "right"}, 200);
          //     $('.notification-element.info').show();
          // }, 2000)
          // setTimeout(() => {
          //     //$('.notification-element.success').show("slide", {direction: "right"}, 200);
          //     $('.notification-element.success').show();
          //     //window.location.href = "https://ibl-aimentorpro.webflow.io/"
          // }, 4000)
          return false;
        }
      }
    );
  };

  useEffect(() => {
    handleFormSubmitTrigger();
    formSubmit();
    handleInvalidFieldFocus();
    handleNotificationCloseClick();
    api.ibledxmfe.getMfeContext((data) => {
      setMfeContext(data);
      console.log(data);
    });
  }, []);

  useEffect(() => {
    handlePasswordEyeClick({
      setPasswordType,
      isPasswordHidden,
      setIsPasswordHidden,
    });
  }, [isPasswordHidden]);
  return (
    <>
      <div className="w-layout-vflex auth-page-container">
        <div className="w-layout-vflex auth-page-block">
          <img src={iblLogo} loading="lazy" alt="" className="auth-logo-img" />
          <div className="w-layout-vflex auth-page-main-content">
            <h2 className="auth-page-title">Welcome Back</h2>
            <div className="auth-form-block w-form">
              <form
                method="get"
                data-auth-actual-level="1"
                name="email-form"
                data-name="Email Form"
                data-auth-level-number="2"
                id="email-form"
                className="auth-form"
                data-wf-page-id="659d7f74d3cc18f619efec06"
                data-wf-element-id="bcda3841-4ea3-bb0c-59a6-36594047bbde"
              >
                <div
                  data-auth-level="1"
                  className="w-layout-vflex auth-input-group visible"
                >
                  <input
                    className="auth-input username w-input"
                    autoFocus={true}
                    maxLength="256"
                    name="name"
                    data-name="Name"
                    placeholder="Email or Username"
                    type="text"
                    id="username"
                    required=""
                  />
                  <div className="text-error hidden">
                    This field is required
                  </div>
                </div>
                <div
                  data-auth-level="2"
                  className="w-layout-vflex auth-input-group"
                >
                  <input
                    className="auth-input password-field password w-input"
                    maxLength="256"
                    name="name-2"
                    data-name="Name 2"
                    placeholder="Password"
                    type={passwordType}
                    id="password"
                  />
                  <div className="text-error hidden">Minimum 8 characters</div>
                  <Link
                    to="/forgot-password"
                    className="auth-link-redirect mt-small"
                  >
                    Forgot password ?
                  </Link>
                  <div className="w-layout-hflex password-eye-block">
                    <img
                      src={!isPasswordHidden ? eyeOff : eye}
                      loading="lazy"
                      alt=""
                      className="password-eye-icon"
                    />
                  </div>
                </div>
                <div className="w-layout-hflex auth-submit-btn">
                  <div className="auth-submit-btn-label">Continue</div>
                  <div className="btn-spinner-block hidden w-embed">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="submit-button-2 w-button"
                  value="Submit"
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="w-layout-hflex auth-redirect-block">
              <div className="text-block-29">Don&#x27;t have an account ?</div>
              <Link to="/signup" className="auth-link-redirect">
                Sign Up
              </Link>
              {/*<a href="#" className="auth-link-redirect">Sign Up</a>*/}
            </div>
            {mfeContext && mfeContext.context_data.providers.length > 0 && (
              <div className="w-layout-hflex auth-seperation-block">
                <div className="div-block-36"></div>
                <div className="text-block-30">OR</div>
                <div className="div-block-36"></div>
              </div>
            )}
            <div className="w-layout-vflex external-auth-block">
              {mfeContext &&
                mfeContext.context_data.providers.map((provider) => (
                  <a
                    href={`${process.env.IBL_WEB_URL}/join?provider=${provider.id}`}
                    className="external-auth-container w-inline-block"
                  >
                    <div className="w-layout-hflex external-auth-btn">
                      <img
                        src={provider.iconClass}
                        loading="lazy"
                        alt=""
                        className="image-31"
                      />
                      <div className="external-auth-label">
                        Continue with {provider.name}
                      </div>
                    </div>
                  </a>
                ))}
              {/* <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={googleLogo} loading="lazy" alt=""
                                    className="image-31"/>
                                    <div className="external-auth-label">Continue with Google</div>
                                </div>
                            </a>
                            <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={appleLogo} loading="lazy" alt="" className="image-31"/>
                                    <div className="external-auth-label">Continue with Apple</div>
                                </div>
                            </a>
                            <a href="#" className="external-auth-container w-inline-block">
                                <div className="w-layout-hflex external-auth-btn"><img
                                    src={microsoftLogo} loading="lazy" alt="" className="image-31"/>
                                    <div className="external-auth-label">Continue with Microsoft Account</div>
                                </div>
                            </a> */}
            </div>
          </div>
          <div className="w-layout-hflex auth-footer">
            <a href="#" className="link-block w-inline-block">
              <div className="auth-footer-links">Terms of Use</div>
            </a>
            <div className="div-block-35"></div>
            <a href="#" className="link-block w-inline-block">
              <div className="auth-footer-links">Privacy Policy</div>
            </a>
          </div>
        </div>
      </div>
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
      {/* <Notifications/> */}
    </>
  );
};

export default Login;
