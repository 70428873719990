import {useEffect, useRef, useState} from "react";
import Chart from "chart.js";

const Verticalgraphcanvas = ({ data, label, index }) => {
  /*INDEX PROPS IS IMPORTANT AS IT SERVES IN THE CANVAS PARENT ID*/
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (data) {
      if (chartRef.current) {
        // Destroy the existing Chart instance
        chartRef.current.destroy();
      }
      fillUpVerticalGraphCard(data);
    }
  }, [data]);

  const fillUpVerticalGraphCard = (data) => {
    const labels = data?.map((course) => course.name);
    const dataValues = data?.map((course) => {
      return course.time_spent !== undefined ? course.time_spent : (course.users !== undefined ? course.users : course.user_count);
    });

    const backgroundColors = [
      "rgba(81, 205, 186, 0.36)",
      "rgba(87, 205, 81, 0.36)",
      "rgba(200, 205, 81, 0.36)",
      "rgba(205, 106, 81, 0.36)",
      "rgba(81,122,205,0.36)",
    ];
    const borderColor = ["#51cdba", "#57cd51", "#c8cd51", "#cd6a51", "#517ACD"];

    const { max, stepSize } = findMaxAndStepSize(dataValues, dataValues.length);

    const ctx = document.getElementById(chartContainerRef.current.id);
    chartRef.current = new Chart(ctx, {
      type: "horizontalBar",
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            backgroundColor: backgroundColors,
            borderColor: borderColor,
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: max,
                stepSize: stepSize,
              },
            },
          ],
          yAxes: [
            {
              barPercentage: 0.4,
              borderWidth: 10,
              stacked: true,
            },
          ],
        },
      },
    });
  };

  const findMaxAndStepSize = (values, numberOfIntervals) => {
    // Step 1: Find the maximum value in the array
    const max = Math.max(...values);

    // Step 2: Calculate the range of the data
    const min = Math.min(...values);
    const range = max - min;

    // Step 3: Determine the step size
    const stepSize = range / numberOfIntervals;

    return { max, stepSize };
  };

  return (
      <canvas ref={chartContainerRef} id={`MeSeStatusCanvasIBL-${index}`} style={{ width: "95%", height: 150 }} />
  );
};

export default Verticalgraphcanvas;
