import { orgSearch, api } from '@iblai/ibl-web-react-common';
import { getTenant } from '../helpers';

export function getAuthoringCourses(
  page = 1,
  size = 10,
  search = '',
  callback
) {
  const data =
    '?filter={"_index":"ibl-dm.public.dl_catalog_app_course"}&page_size=' +
    size +
    '&page=' +
    page +
    `${search === '' ? '' : `&context=${search}`}`;
  orgSearch(data, (data) => {
    callback(data);
  });
}
export function getAuthoringPrograms(
  page = 1,
  size = 10,
  search = '',
  callback
) {
  const data =
    '?filter={"_index":"ibl-dm.public.dl_catalog_app_program"}&page_size=' +
    size +
    '&page=' +
    page +
    `${search === '' ? '' : `&context=${search}`}`;
  orgSearch(data, (data) => {
    callback(data);
  });
}

export const setStudioLogo = (formData, callback) => {
  const token = localStorage.getItem('axd_token');
  const tenant = getTenant();
  fetch(
    `${process.env.REACT_APP_IBL_DM_URL}/api/core/orgs/${tenant}/logo/create/`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Token ' + token,
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error('Error uploading file:', error);
    });
};

export const getCourseMeta = (courseID, callback) => {
  let courseData = {
    course_id: courseID,
  };
  api.ibledxcourses.getCourseMeta(courseData, function (data) {
    callback(data);
  });
};

export const getProgramMeta = (productID, callback) => {
  let data =
    '?detail_indice={"_index":"ibl-dm.public.dl_catalog_app_program"}&detail_scope={"id":"' +
    productID +
    '"}';
  orgSearch(data, function (data) {
    callback(data.results[0]._source.name, data.results[0]._source.program_id);
  });
};
