import React, { useState } from 'react';
import {
  getRamdomCourseImg,
  getStudioProgramCourseURL,
  getTableSizes,
  secondsToHoursAndMinutes,
} from '../../hooks/helpers';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../EmptyTableDataInfographic/EmptyTableDataInfographic';
import TablePagination from '../TablePagination/TablePagination';
import { Link } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import TableSizeDropdown from '../TableSizeDropdown/TableSizeDropdown';

const TABLE_SIZES = getTableSizes();

const Timespenttable = ({ endPoint, title, dataKey = 'data' }) => {
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const [dataHasLoadedOnce, setDataHasLoadedOnce] = useState(false);

  const handleLoadData = () => {
    setDataLoaded(false);
    endPoint(
      {
        length: size,
        page,
      },
      (data) => {
        setRawData(data);
        setData(data?.[dataKey]);
        setDataLoaded(true);
        setDataHasLoadedOnce(true);
      }
    );
  };

  useDebounce(handleLoadData, 300, [page, size]);

  useDebounce(
    () => {
      if (dataHasLoadedOnce) {
        if (search === '') {
          setData(rawData?.[dataKey]);
        } else {
          setData(
            Array.isArray(rawData?.[dataKey])
              ? rawData?.[dataKey].filter((el) =>
                  String(el.name || el.course)
                    .toUpperCase()
                    .includes(String(search).toUpperCase())
                )
              : []
          );
        }
      }
    },
    300,
    [search]
  );

  return (
    <div className="table_cont mgt0">
      <div className="div-block-202 w_t w_w">
        <div className="div-block-169">
          <div>
            <div className="text-block-109">Time Spent Per Course</div>
          </div>
        </div>
        <div className="div-block-169">
          <div className="div-block-171">
            <div className="sm">Show</div>
            <div className="form-block w-form">
              <TableSizeDropdown setSize={setSize} setPage={setPage} />
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="sm">entries</div>
          </div>
          <div className="div-block-171">
            <div className="sm">Search:</div>
            <div className="form-block w-form">
              <form
                data-name="Email Form 2"
                data-wf-element-id="cf68b144-b8b0-58ee-bf65-6b8d2725cae6"
                data-wf-page-id="657af3ad1367da03b3b12581"
                id="email-form-2"
                method="get"
                name="email-form-2"
              >
                <input
                  className="input w-input"
                  data-name="Field 3"
                  id="field-3"
                  maxLength={256}
                  name="field-3"
                  placeholder=""
                  required=""
                  type="text"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setPage(1);
                  }}
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table">
          <div className="th">
            <div className="tc _10">
              <div className="sm">#</div>
            </div>
            <div className="tc">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Course</div>
              </div>
            </div>
            <div className="tc _32-5">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Time Spent</div>
              </div>
            </div>
            <div className="tc _32-5">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Average Time</div>
              </div>
            </div>
          </div>
          {
            <>
              {!dataLoaded ? (
                <IBLSpinLoader
                  color={'#FFF'}
                  size={20}
                  containerHeight={'200px'}
                />
              ) : Array.isArray(data) && data.length > 0 ? (
                data.map((course, index) => (
                  <div className="tr" key={course.course_id}>
                    <div className="tc tcell _10">
                      <div className="m">{index + 1}</div>
                    </div>
                    <div className="tc tcell">
                      <img
                        src={getRamdomCourseImg()}
                        alt=""
                        className="r-i"
                        loading="lazy"
                      />
                      <Link
                        target={'_blank'}
                        className="tl-2 np"
                        to={getStudioProgramCourseURL(course.course_id)}
                      >
                        <span className="nb" prop-coursetitle="">
                          {course.name}
                        </span>
                      </Link>
                    </div>
                    <div className="tc tcell _32-5">
                      <div className="m">
                        {secondsToHoursAndMinutes(course.time_spent)}
                      </div>
                    </div>
                    <div className="tc tcell _32-5">
                      <div className="m">
                        {secondsToHoursAndMinutes(course.average_time)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyTableDataInfographic />
              )}
              {!!rawData?.pagination && !!rawData?.pagination?.totalItems && (
                <TablePagination
                  setPage={setPage}
                  page={page}
                  pagination={rawData?.pagination}
                />
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Timespenttable;
