import Descriptivelinechart from "../Descriptivelinechart/Descriptivelinechart";
import Table from "../Table/Table";
import Linechartgraph from "../Linechartgraph/Linechartgraph";
import Coursetable from "../Coursetable/Coursetable";
import Predictivedatepills from "../Predictivedatepills/Predictivedatepills";
import Enrollmentspercourserow from "../Enrollmentspercourserow/Enrollmentspercourserow";
import Descriptivedatepills from "../Descriptivedatepills/Descriptivedatepills";
import Enrollmentspercoursepagination from "../Enrollmentspercoursepagination/Enrollmentspercoursepagination";
import Predictivelinechart from "../Predictivelinechart/Predictivelinechart";
import React, {useEffect, useState} from "react";
import "./Enrollments.css";
import useEnrollment from "../../hooks/useEnrollment";
import DescriptiveLineChartGraph from "../DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import {randomLineChartGraphData} from "../../hooks/helpers";
import {getEnrollmentsPerCourseAPI} from "../../hooks/utils/enrollments";

const Enrollments = (props) => {
  const {
    enrollmentsOverTime,
    enrollmentsOverTimeLoaded,
    /*enrollmentsPerCourse,
    enrollmentsPerCourseLoaded*/
  } = useEnrollment();


  return (
    <div>
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{width:"100%"}}>
          <DescriptiveLineChartGraph
              data={enrollmentsOverTime}
              dataLoaded={enrollmentsOverTimeLoaded}
              title={"Enrollments Over Time"}
              index={1}
          />
        </div>
        {/*<div className="div-block-204 r">
          <DescriptiveLineChartGraph
              data={randomLineChartGraphData()}
              dataLoaded={tempLoadedState}
              title={"Predicted Enrollments"}
              index={2}
              isPredictive={true}
          />

        </div>*/}
      </div>
      <Coursetable  endPoint={getEnrollmentsPerCourseAPI} title={"Enrollments per Course"}></Coursetable>
    </div>
  );
};

export default Enrollments;
